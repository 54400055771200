<template>
  <div class="editor markup content">
    <b-button class="execute-code" @click="onExecuteCode">Execute Code</b-button>
    <PrismEditor
      v-if="preValue"
      :value="preValue"
      readonly
      :highlight="(code)=>highlighter(code)"
      class="code-fixed-wrapper"
    />
    <PrismEditor
      v-model="code"
      :highlight="(code)=>highlighter(code)"
      class="code-variable-wrapper"
      @message="$emit('message', $event)"
    />
    <PrismEditor
      v-if="postValue"
      :value="postValue"
      readonly
      :highlight="(code)=>highlighter(code)"
      class="code-fixed-wrapper"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator'

import 'prismjs/themes/prism.css'
// import 'prismjs/themes/prism-okaidia.css'

import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import Prism from 'prismjs'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-javascript'
// import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles


@Component({
  components: {
    PrismEditor
  }
})
export default class VInputCodeEditor extends Vue {
  @ModelSync('inputData', 'changeEvent', { type: String, required: true, default: () => '' })
  readonly code!: string


  @Prop({ type: Boolean, required: false, default: () => false })
  readonly readOnly!: boolean

  @Prop({ type: String, required: false, default: () => 'js' })
  readonly language!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly preValue!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly postValue!: string

  public highlighter(code: string) {
    switch (this.language) {
      case 'js':
        return Prism.highlight(code, Prism.languages.javascript, 'javascript')
      case 'html':
        return Prism.highlight(code, Prism.languages.html, 'html')
      default:
        console.warn(`language ${this.language} not supported`)
        break
    }
  }

  public onExecuteCode() {
    this.$emit('execute')
  }
}
</script>

<style lang="scss">
.editor.content {
  position: relative;

  .execute-code {
    position: absolute;
    right: 0.2em;
    top: 0.2em;
    z-index: 30;
  }

  .tag,
  .number {
    display: inline;
    padding: inherit;
    font-size: inherit;
    line-height: inherit;
    text-align: inherit;
    vertical-align: inherit;
    border-radius: inherit;
    font-weight: inherit;
    white-space: inherit;
    background: inherit;
    margin: inherit;
  }

  .token.operator {
    background: none;
  }

  .code-fixed-wrapper pre,
  .code-variable-wrapper pre {
    margin: 0;
  }

  .code-fixed-wrapper {
    background: #f5f2f07a;
  }

  .code-variable-wrapper {
    background: #f5f2f0;
  }

  .code-fixed-wrapper,
  .code-variable-wrapper {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    // background: #2d2d2d;
    color: #ccc;
    padding: 0.7em 1em 0.5em;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 14px;
    line-height: 1.5;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }

  .code-fixed-wrapper pre {
  }

  .code-variable-wrapper pre {
    // padding: 0.5em 1em 0.5em;
    // background: #f5f2f07a;
  }
}
</style>
