<template>
  <main class="column dashboard">
    <!-- <div class="level">
            <div class="level-left">
              <div class="level-item">
                <div class="title">Dashboard</div>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <button type="button" class="button is-small">March 8, 2017 - April 6, 2017</button>
              </div>
            </div>
    </div>-->

    <div class="columns is-multiline">
      <div class="column">
        <div class="box">
          <div class="heading">Activated ECHO Codes</div>
          <div class="title">{{ adminStatsActivatedAsids }}</div>
          <div class="level">
            <div class="level-item">
              <div class>
                <div class="heading">Assigned</div>
                <div class="title is-5">{{ adminStatsAssignedAsidSlots }}</div>
              </div>
            </div>
            <!-- <div class="level-item">
              <div class>
                <div class="heading">Active Codes</div>
                <div class="title is-5">{{ adminStatsActivatedAsids }}</div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="heading">Interactions used</div>
          <div class="title">{{ adminStatsTotalUsedInteractions }}</div>
          <div class="level">
            <div class="level-item">
              <div class>
                <div class="heading">Responses</div>
                <div class="title is-5">{{ adminStatsTotalResponseCount }}</div>
              </div>
            </div>
            <!-- <div class="level-item">
              <div class>
                <div class="heading">Used</div>
                <div class="title is-5">{{ adminStatsTotalUsedInteractions }}</div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="heading">Storage Used</div>
          <div class="title">{{ adminStatsStorageQuotaTotal }}</div>
          <div class="level">
            <div class="level-item">
              <div class>
                <div class="heading">App uploads</div>
                <div class="title is-5">{{ adminStatsStorageQuotaApp }}</div>
              </div>
            </div>
            <div class="level-item">
              <div class>
                <div class="heading">backend upl.</div>
                <div class="title is-5">{{ adminStatsStorageQuotaBackend }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="heading">App PageViews total</div>
          <div class="title">{{ totalVisits }}</div>
          <div class="level">
            <div class="level-item">
              <div class>
                <div class="heading">last month</div>
                <div class="title is-5">{{ lastMonthVisits }}</div>
              </div>
            </div>
            <div class="level-item">
              <div class>
                <div class="heading">current month</div>
                <div class="title is-5">
                  {{ currentMonthVisits }}
                  <span v-if="currentMonthVisits > lastMonthVisits">&uarr;</span>
                </div>
              </div>
            </div>
            <!-- <div class="level-item">
              <div class>
                <div class="heading">Success %</div>
                <div class="title is-5">+ 28%</div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="panel">
          <p class="panel-heading">App Interactions & Visits</p>
          <div class="panel-block">
            <div class="chart-container">
              <VueApexCharts
                v-if="!isLoading"
                height="300"
                :options="timeSeriesTotalInteractionsChartOptions"
                :series="timeSeriesTotalInteractionsAndVisits"
              />
            </div>
          </div>
        </div>
        <div class="panel">
          <p class="panel-heading">Activated & Assigned ECHO CODES</p>
          <div class="panel-block">
            <div class="chart-container">
              <VueApexCharts
                v-if="!isLoading"
                height="300"
                :options="timeSeriesTotalInteractionsChartOptions"
                :series="timeSeriesActivatedAndAssignedAsids"
              />
            </div>
          </div>
        </div>

        <div class="panel">
          <p class="panel-heading">Deepl Usage</p>
          <div class="panel-block">
            <div class="chart-container">
              <VueApexCharts
                v-if="!isLoading"
                height="300"
                :options="timeSeriesTotalInteractionsChartOptions"
                :series="timeSeriesTotalDeeplApi"
              />
            </div>
          </div>
        </div>
        <!--
        <div class="panel">
          <p class="panel-heading">App PageViews (ECHO Code Scans)</p>
          <div class="panel-block">
            <div class="chart-container">
              <VueApexCharts
                v-if="!isLoading"
                height="300"
                :options="timeSeriesVisitsChartOptions"
                :series="timeSeriesVisits"
              />
            </div>
          </div>
        </div>-->
      </div>
      <!-- <div class="column is-6">
        <div class="panel">
          <p class="panel-heading">latest {{ latestAssignedAsids.length }} ECHO Codes</p>
          <div class="panel-block">
            <VTimeSeriesGraph v-if="!isLoading" :series="timeSeriesAsids" />
          </div>
        </div>
      </div>-->
      <div class="column is-6">
        <div class="panel">
          <p class="panel-heading">Module Interactions per Day</p>
          <div class="panel-block">
            <!-- <VTimeSeriesGraph
              v-if="!isLoading"
              :series="timeSeriesInteractionsPerModulePerDay"
              :color="timeSeriesInteractionsPerModuleColors"
              is-date-time
            />-->
            <div class="chart-container">
              <VueApexCharts
                v-if="!isLoading"
                height="300"
                :options="timeSeriesInteractionsChartOptions"
                :series="timeSeriesInteractionsPerModulePerDay"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="panel">
          <p class="panel-heading">Module Interactions per Time</p>
          <div class="panel-block">
            <VTimeSeriesGraph
              v-if="!isLoading"
              :series="timeSeriesInteractionsPerModulePerTime"
              :color="timeSeriesInteractionsPerModuleColors"
              :x-axis-categories="Array.from(Array(24).keys()).flatMap(i=>[`${i}:00`,`${i}:30`])"
            />
          </div>
        </div>
      </div>
      <!-- <div class="column is-12">
        <div class="panel">
          <p class="panel-heading">Latest 5 Responses</p>
          <div class="panel-block">
            <div class="is-fullwidth">
              <VResponseTimelineView
                v-if="!isLoading"
                :responses-form="responsesForm"
                :module-elements-with-type="formElementsTypeAndID"
                :show-asid-info="true"
              />
            </div>
          </div>
        </div>
      </div>-->
      <!-- <div class="column is-6">
        <div class="panel">
          <p class="panel-heading">Something Else</p>
          <div class="panel-block">
            <figure class="image is-16x9">
              <img src="https://placehold.it/1280x720" />
            </figure>
          </div>
        </div>
      </div>-->
    </div>
  </main>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import moment from 'dayjs'

import { ModuleManager } from '@/modules/moduleManager'
import { getStatisticsForMonth, getStatisticsPerDay, getStatisticsPerTime } from '@/helpers/statisticsHelper'


import AsidManager from '@/database/asidManager'

import VTimeSeriesGraph from '@/components/VTimeSeriesGraph.vue'
import { AsidDB } from '@/types/typeAsid'
import { timeSeriesPreparationHelper } from '@/database/dbHelper'


import { BaseResponseDB, BaseModuleDB, ElementWithTypeAndID } from '@/modules/typeModules'
import { hasDBid } from '@/types/typeGeneral'
import { Statistics } from '@/types/typeBase'
import VueApexCharts from 'vue-apexcharts'
import VResponseTimelineView from '@/components/VResponsesTimelineView.vue'
import { FormElementDB, FormResponseDB } from '@/modules/form/typeFormModule'
import StorageManager from '@/helpers/StorageManager'
import databaseSchema from '@/database/databaseSchema'
import { GlobalAdminStatisticsDB, GlobalAdminStatisticsStatistics } from '@/types/typeAdmin'
import db from '@/firebase'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { PlanDB } from '@/types/typePlan'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { handlePromiseError } from '@/helpers/notificationHelper'

@Component({
  components: {
    VTimeSeriesGraph,
    VueApexCharts,
    VResponseTimelineView
  }
})
export default class BackendDashboard extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public isLoading = false
  public moduleDBs: BaseModuleDB[] = []


  public async created() {
    this.isLoading = true
    await this.initActivatedModules()
    await this.initAdminStats()

    this.isLoading = false
  }

  get filteredModuleDBs() {
    return this.moduleDBs
      .filter((mod) => !['Html', 'I18n', 'Data', 'Ci'].includes(mod.public.type))
  }


  // #region latest Form Responses
  public responsesForm: Array<hasDBid & FormResponseDB> = []
  public formElements: (FormElementDB)[] = []

  public get formElementsTypeAndID(): ElementWithTypeAndID[] {
    return this.formElements.map((fe) => ({
      type: 'Form',
      id: (fe as any).id,
      ...fe
    }))
  }


  // #endregion latest Form Responses

  // ASID GRAPH
  public latestAssignedAsids: Array<AsidDB & hasDBid> = []
  public latestActivatedAsids: Array<AsidDB & hasDBid> = []
  public latestInteractions: Array<BaseResponseDB & hasDBid> = []


  get timeSeriesAsids() {
    const groupedDataActivated = timeSeriesPreparationHelper(
      this.latestActivatedAsids.filter((a) => a.dateActivated),
      (obj) => obj.dateActivated.toDate()
    )

    const groupedDataAssigned = timeSeriesPreparationHelper(
      this.latestAssignedAsids.filter((a) => a.dateAssigned),
      (obj) => obj.dateAssigned.toDate()
    )


    return [
      {
        name: 'Activated Echo Codes',
        // data: [Date.now(),Date.now(),Date.now(),Date.now()]
        data: [...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 30].map((t) => ({ x: moment(groupedDataActivated[0]?.x, 'MM/DD/YYYY').add(t, 'days'), y: 0 })), ...groupedDataActivated]
      }, {
        name: 'Assigned Echo Codes',
        // data: [Date.now(),Date.now(),Date.now(),Date.now()]
        data: groupedDataAssigned
      }
    ]
  }


  get timeSeriesInteractions() {
    const groupedDataInteractions = timeSeriesPreparationHelper(
      this.latestInteractions.filter((a) => a._meta.dateCreated !== undefined),
      (obj) => obj._meta.dateCreated.toDate()
    )

    return [
      {
        name: 'Interactions',
        // data: [Date.now(),Date.now(),Date.now(),Date.now()]
        data: [...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 30].map((t) => ({ x: moment(groupedDataInteractions[0]?.x, 'MM/DD/YYYY').add(t, 'days'), y: 0 })), ...groupedDataInteractions]
      }
    ]
  }


  get interactionsPerModule(): { name: string, statistics: Statistics<GlobalAdminStatisticsStatistics> }[] {
    return this.filteredModuleDBs.map((mdb) => ({
      name: mdb.public.type,
      statistics: this.adminStatistics._computed.statistics
    }))
  }


  get timeSeriesInteractionsPerModulePerDay() {
    return this.interactionsPerModule
      .map((ipm) => {
        return {
          name: ipm.name,
          data: getStatisticsPerDay(ipm.statistics).map((d) => ({ x: d.date, y: d.events[ipm.name.toLowerCase().substring(0, 2) as keyof GlobalAdminStatisticsStatistics] || 0 }))
        }
      })
  }

  public get timeSeriesInteractionsChartOptions() {
    return {
      colors: this.timeSeriesInteractionsPerModuleColors,
      chart: {
        animations: {
          enabled: true
        },
        type: 'area',
        // type: 'bar',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          show: true,
          autoSelected: 'zoom',
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false
        }
      },
      stroke: {
        // curve: 'stepline'
      },
      dataLabels: {
        enabled: false
      },
      // markers: {
      //   size: 0
      // },
      // title: {
      //   // text: 'Assigned Codes',
      //   align: 'left'
      // },
      // plotOptions: {
      //   bar: {
      //     columnWidth: '50%' // 3 % for 30 day period?
      //     // endingShape: 'rounded'
      //   }
      // },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            // console.log(val)

            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
        // title: {
        //   // text: 'Price'
        // }
      },
      xaxis: {
        type: 'datetime',
        min: moment(new Date()).subtract(1, 'month').toDate().getTime(),
        max: moment(new Date()).toDate().getTime()
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
      }
    }
  }

  private adminStatistics: GlobalAdminStatisticsDB = cloneObject(databaseSchema.COLLECTIONS.ADMIN.STATISTICS.__EMPTY_DOC__)

  get totalVisits() {
    const totalEvents = this.adminStatistics?._computed?.statistics?.date?.e
    return (totalEvents?.pv || 0)
  }

  get lastMonthVisits() {
    const currentYear = new Date().getUTCFullYear()
    const currentMonth = new Date().getUTCMonth() + 1

    const year = (currentMonth === 1) ? currentYear - 1 : currentYear
    const lastMonth = (currentMonth === 1) ? 12 : currentMonth - 1

    const statistics = this.adminStatistics?._computed?.statistics
    if (!statistics) return 0
    const events = getStatisticsForMonth(statistics, year, lastMonth)
    return events.pv || 0
  }

  get currentMonthVisits() {
    const currentYear = new Date().getUTCFullYear()
    const currentMonth = new Date().getUTCMonth() + 1

    const statistics = this.adminStatistics?._computed?.statistics
    if (!statistics) return 0
    const events = getStatisticsForMonth(statistics, currentYear, currentMonth)
    return events.pv || 0
  }

  get timeSeriesTotalDeeplApi() {
    return [{
      name: 'Requests',
      data: this.adminStatistics?._computed?.statistics
        && getStatisticsPerDay(this.adminStatistics._computed.statistics)
          .map((d) => ({ x: d.date, y: d.events.de || 0 }))
    }, {
      name: 'Responses',
      data: this.adminStatistics?._computed?.statistics
        && getStatisticsPerDay(this.adminStatistics._computed.statistics)
          .map((d) => ({ x: d.date, y: d.events.dr || 0 }))
    }]
  }

  get timeSeriesActivatedAndAssignedAsids() {
    return [{
      name: 'Activated',
      data: this.adminStatistics?._computed?.statistics
        && getStatisticsPerDay(this.adminStatistics._computed.statistics)
          .map((d) => ({ x: d.date, y: d.events.ac || 0 }))
    }, {
      name: 'Assigned',
      data: this.adminStatistics?._computed?.statistics
        && getStatisticsPerDay(this.adminStatistics._computed.statistics)
          .map((d) => ({ x: d.date, y: d.events.as || 0 }))
    }]
  }

  get timeSeriesTotalInteractionsAndVisits() {
    return [{
      name: 'Interactions',
      data: this.adminStatistics?._computed?.statistics
        && getStatisticsPerDay(this.adminStatistics._computed.statistics)
          .map((d) => ({ x: d.date, y: d.events.ti || 0 }))
    },
    {
      name: 'PageViews',
      data: this.adminStatistics?._computed?.statistics
        && getStatisticsPerDay(this.adminStatistics._computed.statistics)
          .map((d) => ({ x: d.date, y: d.events.pv || 0 }))
    }]
  }

  public get timeSeriesTotalInteractionsChartOptions() {
    return {
      colors: ['#9368B7', '#AA3E98', '#9297C4', '#84C7D0', '#75DDDD'],
      chart: {
        animations: {
          enabled: true
        },
        type: 'area',
        // type: 'bar',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          show: true,
          autoSelected: 'zoom',
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false
        }
      },
      dataLabels: {
        enabled: false
      },
      // markers: {
      //   size: 0
      // },
      // title: {
      //   // text: 'Assigned Codes',
      //   align: 'left'
      // },
      // fill: {
      //   type: 'gradient',
      //   gradient: {
      //     shadeIntensity: 1,
      //     inverseColors: false,
      //     opacityFrom: 0.5,
      //     opacityTo: 0,
      //     stops: [0, 90, 100]
      //   }
      // },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            // console.log(val)

            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
        // title: {
        //   // text: 'Price'
        // }
      },
      xaxis: {
        type: 'datetime',
        min: moment(new Date()).subtract(1, 'month').toDate().getTime(),
        max: moment(new Date()).toDate().getTime()
        // max: moment(new Date()).add(1,'week').toDate().getTime()
        // min: moment(new Date()).startOf('month').toDate().getTime(),
        // max: moment(new Date()).endOf('month').toDate().getTime()
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
      }
    }
  }


  get timeSeriesInteractionsPerModulePerTime() {
    return this.interactionsPerModule.map((ipm) => {
      const statisticsData = getStatisticsPerTime(ipm.statistics)
      // hydrate statistiscs with all possible time, since apexcharts needs all entries when providing data
      const data = Array.from(Array(24).keys())
        .flatMap((i) => [`${String(i).padStart(2, '0')}:00`, `${String(i).padStart(2, '0')}:30`])
        .map((time) => {
          const entryForTime = statisticsData.find((sd) => sd.time === time)
          return { time, events: { rc: entryForTime?.events[ipm.name.toLowerCase().substring(0, 2) as keyof GlobalAdminStatisticsStatistics] || 0 } }
        })
      return {
        name: ipm.name,
        data: data.map((d) => ({ x: d.time, y: d.events.rc || 0 }))
      }
    })
  }


  // ACTIVE_MODULES
  public timeSeriesInteractionsPerModuleColors: string[] = []

  public async initActivatedModules() {
    this.moduleDBs = ModuleManager.moduleClasses.map((mc) => mc.moduleDB)
    this.timeSeriesInteractionsPerModuleColors = this.filteredModuleDBs.map((m) => ModuleManager.getModuleClassByType(m.public.type).color)
  }
  /// / ACTIVE_MODULES


  // ADMINSTATS_DATA
  public adminStatsActivatedAsids = 0
  public adminStatsAssignedAsidSlots = 0
  public adminStatsTotalUsedInteractions = 0

  public adminStatsAvailableInteractions = 0
  public adminStatsTotalResponseCount = 0

  public adminStatsStorageQuotaTotal = ''
  public adminStatsStorageQuotaApp = ''
  public adminStatsStorageQuotaBackend = ''


  private async initAdminStats() {
    return new Promise((res, rej) =>
      handlePromiseError(
        this.$bindSnapshot<GlobalAdminStatisticsDB>('adminStatistics', db.doc(databaseSchema.COLLECTIONS.ADMIN.STATISTICS.__DOCUMENT_PATH__()),
          (adminStats) => {
            // use adminStats here to ba able to use same dashboard as in backend
            const data: PlanDB = {
              ...databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__EMPTY_DOC__,
              _computed: {
                ...databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__EMPTY_DOC__._computed,

                activatedAsids: adminStats._computed.statistics.date.e.ac || 0,
                assignedAsids: adminStats._computed.statistics.date.e.as || 0,
                totalUsedInteractions: adminStats._computed.statistics.date.e.ti || 0,
                totalUsedStorageQuota: adminStats._computed.statistics.date.e.ts || 0,
                totalUsedBackendStorageQuota: adminStats._computed.statistics.date.e.tb || 0,
                totalUsedAppStorageQuota: adminStats._computed.statistics.date.e.ta || 0
              }
            }
            this.adminStatsTotalResponseCount = adminStats._computed.statistics.date.e.to
            this.adminStatsActivatedAsids = data._computed.activatedAsids
            this.adminStatsAssignedAsidSlots = data._computed.assignedAsids
            this.adminStatsTotalUsedInteractions = data._computed.totalUsedInteractions
            this.adminStatsStorageQuotaTotal = StorageManager.returnFileSize(data._computed.totalUsedStorageQuota)
            this.adminStatsStorageQuotaApp = StorageManager.returnFileSize(data._computed.totalUsedAppStorageQuota)
            this.adminStatsStorageQuotaBackend = StorageManager.returnFileSize(data._computed.totalUsedBackendStorageQuota)
            console.log(data)

            this.adminStatsAvailableInteractions = AsidManager.getAvailableInteractionsCount(data)
            res('')
            return adminStats
          })
      )
    )
  }
  /// / ADMINSTATS_DATA


  // ASID_STATS

  /// / ASID_STATS
}
</script>

<style lang="scss">
.dashboard {
  .level {
    .level-item {
      flex-grow: 0 !important;
      justify-content: space-between;
    }
  }

  .chart-container {
    width: 100%;
  }
}
</style>
