<template>
  <section>
    <b-field label="Tenant">
      <VDocSelectionView
        v-model="tenantIdModel"
        placeholder="Tenant Name or Number"
        :input-formatter="(t)=>`${t.name} - number: ${formatPadded(t._number)} `"
        :collection-path="collectionPath"
        :query-like-props="queryLikeProps"
        :query-exact-props="queryExactProps"
      >
        <template #dropdown="{option}">
          <div>
            {{ option.name }} - number: {{ formatPadded(option._number) }}
            <br />
            <small>Address: {{ option.masterData.billingAddress.city || 'no city' }}, {{ option.masterData.billingAddress.street || 'no street' }}</small>
          </div>
        </template>
      </VDocSelectionView>
    </b-field>
  </section>
</template>


<script lang="ts">
import TenantManager from '@/database/tenantManager'
import { Component, ModelSync, Vue } from 'vue-property-decorator'


import VDocSelectionView from './VDocSelectionView.vue'


@Component({
  components: { VDocSelectionView }
})
export default class VAdminTenantSelectionView extends Vue {
  @ModelSync('tenantID', 'tenantSelected', { type: String, required: true })
  public readonly tenantIdModel!: string

  public collectionPath = 'Tenants'
  public queryLikeProps = ['name']
  public queryExactProps = ['_number']

  public formatPadded(number: number) {
    return TenantManager.formatPaddedNumber(number)
  }
}
</script>

<style lang="scss">
</style>
