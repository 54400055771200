import '@/class-component-hooks'
import '@/firebase'
import helpers from '@/helpers/globalHelpers'

import i18n from '@/modules/_globals/i18nGlobals'
import localSettings from '@/modules/_globals/localSettingsGlobals'
import Vue from 'vue'
import Buefy from 'buefy'
import { firestorePlugin } from 'vuefire'
import vueDebounce from 'vue-debounce'
import vueSanitize from 'vue-sanitize'

import router from './router'
import App from '@/App.vue'
import adminAuth from '@/helpers/adminAuthUtil'

import '@/main.scss'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as Sentry from '@sentry/vue'
import vueShortkey from 'vue-shortkey'

import '@/types/typeVuefire'

console.log('NODE_ENV', process.env.NODE_ENV)
console.log('VUE_APP_ENV', process.env.VUE_APP_ENV)

if (process.env.NODE_ENV !== 'development')
  Sentry.init({
    Vue,
    dsn: 'https://8776087e6029409f9da4fe8f6b546756@o1296785.ingest.sentry.io/6705328',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    debug: ['local', 'dev'].includes(process.env.VUE_APP_ENV || ''),
    environment: process.env.VUE_APP_ENV,
    release: `${process.env.VUE_APP_VERSION}_${(+(process.env.VUE_APP_BUILD_TIME || 1)).toString(36)}`,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    logErrors: true
  })

Vue.use(vueDebounce)
Vue.use(vueSanitize)
Vue.use(vueShortkey)

Vue.use(i18n)
Vue.use(localSettings)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

// #region global component registration
import '@/components/global/globalComponentRegistration'
// #endregion global component registration

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconComponent: 'font-awesome-icon',
  defaultNotificationPosition: 'is-top',
  defaultNotificationDuration: 10000
})

Vue.use(firestorePlugin as any, { wait: true })

// Vue.mixin(VCustomVueFireBindMixin)
declare module 'vue/types/vue' {
  interface Vue {
    $bindSnapshot<T>(
      varName: string,
      ref: firebase.default.firestore.DocumentReference<firebase.default.firestore.DocumentData>,
      beforeSet?: (data: T) => T,
      onError?: (e: any) => void
    ): Promise<unknown>
  }
}

Vue.use(helpers)
Vue.use(adminAuth)

Vue.filter('lowercase', function (value: string) {
  if (!value) return ''
  return value.toLowerCase()
})

Vue.config.productionTip = false
; (window as any).VueInst = new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
