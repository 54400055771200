<template>
  <section>
    <div class="columns qr-generator">
      <div class="column">
        <!-- <VEchoCode
          :show-print="formShowPrint"
          :code-config="codeConfig"
          :asid="formSampleAsid"
          :svg-template-text="interpolatedSVG"
        />-->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div ref="qr_code" class="qr_code-preview" v-html="qrCode" />
      </div>
      <div class="column box">
        <h1 class="subtitle">Input</h1>

        <b-field label="URL">
          <b-field>
            <b-input v-model="url" expanded />

            <p class="control">
              <b-button @click="url = url.toUpperCase()">Optimize</b-button>
            </p>
          </b-field>
        </b-field>

        <b-field label="ECL">
          <b-select v-model="ecl">
            <option value="L">L</option>
            <option value="M">M</option>
            <option value="Q">Q</option>
            <option value="H">H</option>
          </b-select>
        </b-field>

        <b-field label="Background">
          <VInputColorpicker v-model="bgColor" allow-reset color-format="hex" />
        </b-field>
        <b-field label="Foreground">
          <VInputColorpicker v-model="fgColor" allow-reset color-format="hex" />
        </b-field>

        <b-button @click="onDownload">Download QR Code</b-button>
      </div>

      <div class="column box">
        <h1 class="subtitle">Info</h1>
        <div>
          <div class="label">Version: {{ qrStats.version }}</div>
          <div class="label">Mode: {{ qrStats.mode }}</div>
          <div class="label">Mask: {{ qrStats.mask }}</div>
          <div class="label">ECL: {{ qrStats.ecl }}</div>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import VInputColorpicker from '@/components/VInputColorpicker.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { saveAs } from 'file-saver'
import 'prismjs'
import 'prismjs/themes/prism.css'
// import PrismEditor from 'vue-prism-editor'
import AsidManager from '@/database/asidManager'

library.add(faUndo)


@Component({
  components: { VInputColorpicker }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminQRGenerator extends Vue {
  public qrStats: {
    mask: number
    version: number
    ecl: string
    mode: string
    QRsvg: string
  } = {
      mask: 0,
      version: 0,
      ecl: '',
      mode: '',
      QRsvg: ''
    }

  public url: string = ''
  public ecl: 'L' | 'M' | 'Q' | 'H' = 'L'
  public bgColor: string = 'black'
  public fgColor: string = 'white'

  get qrCode() {
    this.qrStats = AsidManager.getDebugQrCodeSvg(
      this.url,
      this.ecl,
      this.bgColor,
      this.fgColor
    )

    return this.qrStats.QRsvg
  }

  public onDownload() {
    const svgText = (this.$refs.qr_code as Element).innerHTML
    const svgBlob = new Blob([svgText], { type: 'image/svg+xml;charset=utf-8' })
    console.log('sdsdsdsdsd')

    saveAs(svgBlob, `QR_CODE-${this.url}.svg`)
  }

  public async mounted() {
    // this.isLoading = true
    // this.tasks.forEach((t) => {
    //   t.inputPayloadString = JSON.stringify(t.taskData.payload)
    // })
    // this.isLoading = false
  }
}
</script>

<style lang="scss">
.qr-generator {
  .column {
    margin: 0 0.5rem;
  }
}
</style>
