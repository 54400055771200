<template>
  <section>
    <b-button @click="onStartExport">Export Echo Codes</b-button>

    <b-modal :active.sync="isModalexportAsidCodesActive" :width="640" scroll="keep">
      <div class="modal-card changelog-diff" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">Export {{ asidDocs.length }} Codes</p>
          <button type="button" class="delete" @click="isModalexportAsidCodesActive = false" />
        </header>
        <section class="modal-card-body">
          <b-steps v-model="activeStep" :animated="true" :rounded="true" :has-navigation="false">
            <b-step-item step="1" label="Asids">
              <h1 class="title has-text-centered">Asid Selection</h1>

              <b-notification
                type="is-info"
                :closable="false"
                aria-close-label="Close notification"
              >{{ stateLoadAsids }}</b-notification>
              <!--
              <b-field>
                <p class="control">
                  <b-button expanded type="is-success" @click="loadAsidsForBatchID">load Asids</b-button>
                </p>
              </b-field>-->
            </b-step-item>

            <b-step-item step="2" label="Export Type">
              <h1 class="title has-text-centered">Export Type</h1>

              <b-select v-model="formExportType" placeholder="Default">
                <option key="sticker" value="sticker">Sticker</option>
                <option key="qr" value="qr">QR</option>
                <option key="csv" value="csv">CSV</option>
              </b-select>

              <hr />

              <b-field>
                <p class="control">
                  <b-button expanded :disabled="formExportType === ''" @click="activeStep++">next</b-button>
                </p>
              </b-field>
            </b-step-item>

            <b-step-item step="3" label="Additional Data">
              <h1 class="title has-text-centered">Identifier / Data Module</h1>

              <b-taginput
                v-if="backendConfig"
                v-model="formExportIdentifiers"
                :data="formFilteredIdentifiers"
                autocomplete
                :allow-new="false"
                open-on-focus
                icon="tag"
                placeholder="Select Identifiers"
                @typing="(value)=>setIdentifierSelectFilterText(value, formExportIdentifiers)"
              >
                <template slot-scope="props">
                  {{ backendConfig.asid.identifierDefinition[props.option].title||backendConfig.asid.identifierDefinition[props.option].name }}
                  <small>#{{ props.option }}</small>
                  <!-- {{ props }} -->
                </template>
                <template
                  #tag="props"
                >{{ backendConfig.asid.identifierDefinition[props.tag].title||backendConfig.asid.identifierDefinition[props.tag].name }}</template>
              </b-taginput>
              <span
                v-else
              >No Asid config found. This may be caused by the batch Asids are assigned to multiple or none tenants</span>

              <hr />

              <b-field>
                <p class="control">
                  <b-button expanded :disabled="formExportType === ''" @click="activeStep++">next</b-button>
                </p>
              </b-field>
            </b-step-item>

            <b-step-item step="4" label="Overview">
              <h1 class="title has-text-centered">Export Overview</h1>
              <div>
                <div>
                  <b>Numer of ECHO codes & Stickers:</b>
                  {{ asidDocs.length }}
                </div>
                <div>
                  <b>Export Type</b>
                  {{ formExportType }}
                </div>
                <div v-if="batch">
                  <b>Batch:</b>
                  {{ batch.name }}
                </div>
                <div v-if="batch">
                  <b>Batch ID:</b>
                  {{ batch.id }}
                </div>
                <div>
                  <b>All Asids same Tenant:</b>
                  <b-tag
                    :type="Object.keys(checkAsidsResult.tenantIDs).length === 1 ? 'is-success' : 'is-danger'"
                  >{{ Object.keys(checkAsidsResult.tenantIDs).length === 1 ? 'yes' : 'no' }}</b-tag>
                  <b-taglist>
                    <b-tag
                      v-for="tag in Object.keys(checkAsidsResult.tenantIDs)"
                      :key="tag"
                    >{{ tag }}</b-tag>
                  </b-taglist>
                </div>

                <div>
                  <b>All Asids same Sticker Template:</b>
                  <b-tag
                    :type="Object.keys(checkAsidsResult.templateURLs).length === 1 ? 'is-success' : 'is-danger'"
                  >{{ Object.keys(checkAsidsResult.templateURLs).length === 1 ? 'yes' : 'no' }}</b-tag>
                  <b-taglist>
                    <b-tag
                      v-for="tag in Object.keys(checkAsidsResult.templateURLs)"
                      :key="tag"
                    >{{ tag }}</b-tag>
                  </b-taglist>
                </div>

                <div>
                  <b>Exported Identifiers:</b>
                  <b-tag
                    :type="formExportIdentifiers.length === 0 ? 'is-success' : 'is-warning'"
                  >{{ formExportIdentifiers.length === 0 ? 'no' : 'yes' }}</b-tag>
                  <b-taglist>
                    <b-tag v-for="tag in formExportIdentifiers" :key="tag">{{ tag }}</b-tag>
                  </b-taglist>
                </div>
                <!-- <div>
                  <b>Assigned Tenant:</b>
                  {{ (createAsidCodesSelectedTenant && createAsidCodesSelectedTenant.name) || 'none - generic code' }}
                </div>
                <div>
                  <b>ECHO Code Base Url:</b>
                  {{ formCreateAsidCodesBaseUrl }}
                </div>
                <div>
                  <b>ECHO customText:</b>
                  {{ formSelectedCodeConfig.customText }}
                </div>
                <div>
                  <b>ECHO width:</b>
                  {{ formSelectedCodeConfig.width }}mm
                </div>
                <div>
                  <b>ECHO height:</b>
                  {{ formSelectedCodeConfig.height }}mm
                </div>-->
              </div>

              <hr />

              <b-field>
                <p class="control">
                  <b-button expanded @click="activeStep++">Confirm and Export</b-button>
                </p>
              </b-field>
            </b-step-item>

            <b-step-item step="5" label="Export">
              <b-notification
                type="is-info"
                :closable="false"
                aria-close-label="Close notification"
              >{{ state }}</b-notification>

              <b-field>
                <p class="control">
                  <b-button expanded @click="isModalexportAsidCodesActive = false">close</b-button>
                </p>
              </b-field>
            </b-step-item>

            <!-- <b-step-item step="5" label="Finish" :type="{'is-success': true}">
              <h1 class="title has-text-centered">Finish</h1>Yur request has been sent.
            </b-step-item>-->
          </b-steps>
        </section>
        <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" />
      </div>
    </b-modal>
  </section>
</template>


<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import csv from 'papaparse'

import { AsidDB, isAssetAttributeKey, isIdentifierKey } from '@/types/typeAsid'

import AsidManager from '@/database/asidManager'

import BackendConfigManager from '@/database/backendConfigManager'
import { BackendConfigDB } from '@/types/typeBackendConfig'


import { hasDBid } from '@/types/typeGeneral'
import { DataCache } from '@/helpers/dataCache'
import { DataElementDB } from '@/modules/data/typeDataModule'
import DataModule from '@/modules/data/dataModule'
import { ModuleManager } from '@/modules/moduleManager'
import CategoryHelper from '@/database/categoryHelper'
import { CategoryCollection } from '@/types/typeCategory'
import { deepCompare } from '@/helpers/dataShapeUtil'
import JSZip from 'jszip'
import { downloadBlob } from '@/helpers/downloadHelper'
import BatchManager from '@/database/batchManager'
import { BatchDB } from '@/types/typeBatch'
import { TenantDB } from '@/types/typeTenant'
import TenantManager from '@/database/tenantManager'


@Component({
  components: {

  }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class VAdminExportAsidCodes extends Vue {
  // #region modal
  public isModalexportAsidCodesActive = false
  public activeStep = 0
  // #endregion modal


  // #region select Identifiers
  public formExportIdentifiers = []

  private filterText = ''
  private selected: string[] = []

  public setIdentifierSelectFilterText(text: string, selected: string[]) {
    this.filterText = text
    this.selected = selected
  }

  public get formFilteredIdentifiers() {
    return this.identifierDefinitions
      .filter((option) => !this.selected.includes(option.name)) // remove alrdy selected
      .filter((option) => {
        return option.name.toLowerCase()
          .indexOf(this.filterText.toLowerCase()) >= 0
      }).map((identifier) => identifier.__identifierKey__)
  }

  public get identifierDefinitions() {
    if (!this.backendConfig) return []
    return Object.entries(this.backendConfig.asid.identifierDefinition).map(([key, value]) => ({
      __identifierKey__: key,
      ...value
    }))
      .filter((e) => e.name || e.title)
      .sort((a, b) => a.order - b.order)
  }

  public backendConfig: BackendConfigDB | null = null
  public async getBackendConfig() {
    if (this.asidDocs[0].tenantID)
      this.backendConfig = await BackendConfigManager.get(this.asidDocs[0].tenantID)
  }
  // #endregion select Identifiers

  public isLoading = false


  @Prop({ required: false })
  public batchID!: string

  @Prop({ required: false })
  public asidIDs!: string[]

  private dataCacheDataElement = new DataCache<Array<DataElementDB & hasDBid>>(async (key) => {
    return await DataModule.getElementsDocs<DataElementDB & hasDBid>(key)
  })

  private dataCacheTenant = new DataCache<TenantDB & hasDBid | undefined>(async (key) => {
    if (!key) return undefined
    return await TenantManager.get(key)
  })

  private dataCacheBackendConfig = new DataCache<BackendConfigDB>(async (key) => {
    return await BackendConfigManager.get(key)
  })

  private dataCacheCategories = new DataCache<CategoryCollection>(async (key) => {
    return await CategoryHelper.getCategoriesCollection(key)
  })

  private dataCacheCodeTemplateSVG = new DataCache<string>(async (key) => {
    return await AsidManager.getCodeTemplateSVGTextFromUrl(key)
  })

  private asidDocs: (AsidDB & hasDBid & {
    dataElement?: DataElementDB
    backendConfig?: BackendConfigDB
    tenant?: TenantDB
    templateSVGString?: string
  })[] = []

  private batch: BatchDB & { id: string } | null = null

  public formExportType: string = 'qr'

  public stateLoadAsids: string = 'ready...'

  @Watch('state')
  public log() {
    console.log(this.state)
  }

  public checkAsidsResult = {
    tenantIDs: {},
    templateURLs: {},
    checked: false
  }

  private async checkAsids() {
    try {
      this.isLoading = true
      this.checkAsidsResult.checked = false

      const asidDocs = this.asidDocs

      const incrementProp = (obj: any, prop?: string | null) => {
        prop = prop || 'none'

        if (!obj[prop])
          this.$set(obj, prop, 0)

        obj[prop]++
      }

      asidDocs.forEach((ad) => {
        incrementProp(this.checkAsidsResult.tenantIDs, ad.tenantID)
        incrementProp(this.checkAsidsResult.templateURLs, ad.codeConfig.svgTemplateUrl)
      })

      this.checkAsidsResult.checked = true
    } catch (error: any) {
      this.$helpers.notification.Error(`Error occured while checking Asid codes: ${error.toString()}`)
    } finally {
      this.isLoading = false
    }
  }

  @Watch('activeStep', { immediate: true })
  public async stateMachine() {
    switch (this.activeStep) {
      case 0: // select asids
        this.isLoading = true
        if (this.batchID) {
          await this.loadAsidsForBatchID()
        }
        this.activeStep = 1
        this.isLoading = false
        break

      case 1: // wait for user to select export type
        break

      case 2: // select Identifiers and DataModule
        this.isLoading = true
        await this.getBackendConfig()
        this.isLoading = false

        break

      case 3: // overview
        await this.checkAsids()
        break

      case 4: // export
        this.isLoading = true
        await this.prepareAsids()
        await this.onDownloadAsidCodes()
        this.isLoading = false
        break

      default:
        break
    }
  }

  private prepareAsids() {
    return new Promise((res, rej) => {
      let isAdditionalRowPropsLoading = 0

      const updateState = () => {
        this.state = `preparing Asids ${((this.asidDocs.length - isAdditionalRowPropsLoading / 3) / this.asidDocs.length * 100).toFixed(2)}%
         - ${(this.asidDocs.length - isAdditionalRowPropsLoading / 3).toFixed(2)}/${this.asidDocs.length}`
      }

      this.state = 'preparing Asids'
      this.asidDocs.forEach((doc) => {
        if (doc.tenantID) {
          isAdditionalRowPropsLoading++
          this.dataCacheTenant.get(doc.tenantID).then(async (d) => {
            this.$set(doc, 'tenant', d)
            isAdditionalRowPropsLoading--

            updateState()

            if (isAdditionalRowPropsLoading === 0) res(1)
          }).catch(() => {
            this.$helpers.notification.Error(`Tenant with ID ${doc.tenantID} not found`)
          })


          isAdditionalRowPropsLoading++
          this.dataCacheDataElement.get(doc.tenantID).then(async (d) => {
            // get the date Element with the
            const dataElement = ModuleManager.filterElementsMatchingReferences(d,
              await this.dataCacheCategories.get(doc.tenantID || ''),
              doc.categoryIDs,
              doc.id,
              doc.identifierValue
            )
            this.$set(doc, 'dataElement', dataElement[0])
            isAdditionalRowPropsLoading--

            updateState()

            if (isAdditionalRowPropsLoading === 0) res(1)
          }).catch(() => {
            this.$helpers.notification.Error(`Data Element with ID ${doc.tenantID} not found`)
          })

          isAdditionalRowPropsLoading++
          this.dataCacheBackendConfig.get(doc.tenantID).then(async (d) => {
            this.$set(doc, 'backendConfig', d)
            isAdditionalRowPropsLoading--

            updateState()

            if (isAdditionalRowPropsLoading === 0) res(1)
          }).catch(() => {
            this.$helpers.notification.Error(`Backend Config with ID ${doc.tenantID} not found`)
          })
        }

        isAdditionalRowPropsLoading++
        this.dataCacheCodeTemplateSVG.get(doc.codeConfig.svgTemplateUrl).then(async (d) => {
          this.$set(doc, 'templateSVGString', d)
          isAdditionalRowPropsLoading--

          updateState()

          if (isAdditionalRowPropsLoading === 0) res(1)
        }).catch(() => {
          this.$helpers.notification.Error(`Code Template with URL ${doc.codeConfig.svgTemplateUrl} not found`)
        })
      })

      if (isAdditionalRowPropsLoading === 0) {
        res(1)
        this.state = 'preparing Asids 100%'
      }
    })
  }

  private async loadAsidsForBatchID() {
    if (this.batchID) {
      this.stateLoadAsids = `retrieving asid codes for batch ${this.batchID}`
      this.asidDocs = await AsidManager.getWhere({ batchIDs: [] }, 'array-contains', this.batchID)
      this.stateLoadAsids = `retrieved ${this.asidDocs.length} asid codes for batch ${this.batchID}`
      this.batch = await BatchManager.get(this.batchID)
    }
  }

  public state = ''
  public async onStartExport() {
    this.isModalexportAsidCodesActive = true
    this.activeStep = 0
  }


  public async onDownloadAsidCodes(qrOnly = false) {
    this.isLoading = true

    for (const asid of this.asidDocs) {
      if (!deepCompare(this.asidDocs[0].codeConfig, asid.codeConfig)) {
        this.$helpers.notification.Warn('not all exposted codes share the same code template. This might be a mistake')
        break
      }
    }


    const zip = new JSZip()
    const folder = zip.folder('echo-codes')

    if (!folder) {
      throw 'zip download is not supported by your browser'
    }


    let index = 0
    switch (this.formExportType) {
      case 'qr':
      case 'sticker':
        for (const asid of this.asidDocs) {
          let baseUrl = asid?.backendConfig?.asid?.baseUrl || ''
          let backendConfig = asid?.backendConfig
          const svgTemplateText = asid.templateSVGString || ''

          let svgString = ''
          if (this.formExportType === 'qr') {
            svgString = AsidManager.getQrCodeSvg(asid.id, baseUrl, asid.codeConfig.errorCorrectionLevel)
          } else if (this.formExportType === 'sticker') {
            let identifierNameeKeyedObject = {}
            let attributeNameKeyedObject = {}

            // create object with identifier, attribute names
            identifierNameeKeyedObject = Object.keys(asid.identifierValue).reduce((acc, key) => {
              if (backendConfig !== undefined)
                acc[backendConfig.asid.identifierDefinition[key as isIdentifierKey].name] = asid.identifierValue[key as keyof typeof asid.identifierValue]
              return acc
            }, {} as any)

            attributeNameKeyedObject = Object.keys(asid.assetAttributeValue).reduce((acc, key) => {
              if (backendConfig !== undefined)
                acc[backendConfig.asid.assetAttributeDefinitions[key as isAssetAttributeKey].name] = asid.assetAttributeValue[key as keyof typeof asid.assetAttributeValue]
              return acc
            }, {} as any)


            svgString = (await AsidManager.getCodeSVG(
              asid.id,
              baseUrl,
              svgTemplateText,
              asid.codeConfig.errorCorrectionLevel,
              [asid.codeConfig.customText, asid.codeConfig.customText2],
              asid.codeConfig.logoUrl,
              asid.codeConfig.color,
              true,
              {
                identifier: {
                  ...identifierNameeKeyedObject,
                  ...asid.identifierValue
                },
                attribute: {
                  ...attributeNameKeyedObject,
                  ...asid.assetAttributeValue
                },
                ...(asid.dataElement) && { data: asid.dataElement.data }
              },
              asid.categoryIDs,
              undefined,
              backendConfig,
              {
                error: (error) => this.$helpers.notification.Error('Sticker Template: ' + error),
                warn: (warn) => this.$helpers.notification.Warn('Sticker Template: ' + warn)
              }
            )).innerHTML
          } else {
            //
          }
          folder.file(`asid_${++index}_${asid.id}.svg`, svgString)
        }
        // break

      // eslint-disable-next-line no-fallthrough
      case 'csv':
      {
        const csvUnparsingOptions = {
          quotes: false, // or array of booleans
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ',',
          header: true,
          newline: '\r\n',
          skipEmptyLines: false // or 'greedy',
          // columns //or array of strings
        }
        // https://github.com/zeMirco/json2csv


        const csvData = this.asidDocs.map((asid) => {
          let baseUrl = asid?.backendConfig?.asid?.baseUrl || ''
          const identifiers = (this.formExportIdentifiers.length > 0)
            ? this.formExportIdentifiers.reduce((obj, identifierID) => {
              return {
                ...obj,
                [identifierID]: asid.identifierValue[identifierID]
              }
            }, {} as any)
            : []

          const qrCodeStats = AsidManager.getQrCodeStatistics(asid.id, baseUrl, asid.codeConfig.errorCorrectionLevel)
          return {
            asid: asid.id,
            batchName: this.batch?.name,
            tenantName: asid.tenant?.name,
            QRerrorCorrectionLevel: qrCodeStats.ecl,
            QRmode: qrCodeStats.mode,
            QRmask: qrCodeStats.mask,
            QRversion: qrCodeStats.version,
            url: AsidManager.createLink(asid.id, baseUrl),
            ...identifiers // {i1: 'abc'}
          }
        })
        folder.file('asids.csv', csv.unparse(csvData, csvUnparsingOptions))

        break
      }
      default:
        break
    }


    folder.file('export_data.txt',
      (this.batch)
        ? BatchManager.generatePrintExportTxt(this.batch)
        : `
ECHO CODE Export
----
number of codes: ${this.asidDocs.length}
`)

    await folder.generateAsync({ type: 'blob' })
      .then((content) =>
        downloadBlob(content, `${this.asidDocs.length}_${qrOnly ? 'QR' : 'echo'}-codes_${this?.batch?.name.replaceAll('.', '-').replaceAll(' ', '_')}_${this?.batch?.id.replaceAll('.', '-').replaceAll(' ', '_')}`)
      )

    this.isLoading = false
  }


  public mounted() {
    // this.initCreateAsid()
  }
}
</script>

<style lang="scss">
.tenant-modal {
  .modal-card {
    overflow: initial;
  }

  .modal-card-body {
    overflow: visible;
  }
}

input.input[lowercase='true'] {
  text-transform: lowercase;
}
</style>
