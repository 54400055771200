<template>
  <b-autocomplete
    ref="autocomplete"
    v-model="autocompleteText"
    v-debounce:200ms="onGetDocData"
    :data="docs"
    placeholder="Doc Name or Number"
    :custom-formatter="inputFormatter"
    :loading="isFetchingDocs"
    open-on-focus
    @select="onSelectDoc"
  >
    <template #default="props">
      <slot name="dropdown" :option="props.option">
        <div>{{ props.option.name || '' }} - id: {{ props.option.id }}</div>
      </slot>
    </template>
  </b-autocomplete>
</template>


<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import db from '@/firebase'
import { hasDBid } from '@/types/typeGeneral'
import { BaseDB } from '@/types/typeBase'


@Component({
  components: {}
})
export default class VDocSelectionView extends Vue {
  @Model('docSelected', { type: String, required: true })
  public readonly docIdModel!: string


  @Prop({ required: true, type: String })
  private readonly collectionPath!: string

  @Prop({ required: true, type: Array })
  private readonly queryLikeProps!: string[]

  @Prop({ required: true, type: Array })
  private readonly queryExactProps!: string[]


  @Prop({ required: false, type: Function, default: () => (t: BaseDB & hasDBid) => `id: ${t.id} ` })
  public readonly inputFormatter!: (d: BaseDB & hasDBid) => string


  public isFetchingDocs = false
  public docs: Array<BaseDB & hasDBid> = []

  public autocompleteText = ''

  private LIMIT = 5


  private onSelectDoc(doc: BaseDB & hasDBid) {
    if (!doc?.id) return

    this.$emit('docSelected', doc.id)
  }

  @Watch('docIdModel', { immediate: true })
  private async onChangeDocID() {
    if (!this.docIdModel) return

    try {
      let doc = this.docs.find((t) => t.id === this.docIdModel)
      if (!doc) {
        this.isFetchingDocs = true
        const docRes = await db.collection(this.collectionPath).doc(this.docIdModel).get()

        if (!docRes.exists) throw `doc with id: ${this.docIdModel} not found`

        doc = { ...docRes.data() as BaseDB, id: docRes.id }
        this.docs.push(doc)
      }
      (this.$refs.autocomplete as any).setSelected(doc)
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isFetchingDocs = false
    }
  }


  public onGetDocData(searchString: string) {
    this.isFetchingDocs = true
    // this.docs = []


    const capitalize = (s: string) => {
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    let queries = []

    if (searchString === '') {
      // just get some results. If the result set is small enough it will just display all options
      queries = [
        db.collection(this.collectionPath).limit(this.LIMIT).get().then((data) => {
          return data
        })
      ]
    } else {
      queries = [
        ...this.queryLikeProps.flatMap((likeProp) => [
          db.collection(this.collectionPath).where(likeProp, '>=', searchString).where(likeProp, '<=', searchString + '\uf8ff').limit(this.LIMIT).get().then((data) => {
            return data
          }),
          db.collection(this.collectionPath).where(likeProp, '>=', searchString.toLowerCase()).where(likeProp, '<=', searchString.toLowerCase() + '\uf8ff').limit(this.LIMIT).get().then((data) => {
            return data
          }),
          db.collection(this.collectionPath).where(likeProp, '>=', searchString.toUpperCase()).where(likeProp, '<=', searchString.toUpperCase() + '\uf8ff').limit(this.LIMIT).get().then((data) => {
            return data
          }),
          db.collection(this.collectionPath).where(likeProp, '>=', capitalize(searchString)).where(likeProp, '<=', capitalize(searchString) + '\uf8ff').limit(this.LIMIT).get().then((data) => {
            return data
          })
        ]),
        ...this.queryExactProps.flatMap((exactProp) => [
          db.collection(this.collectionPath).where(exactProp, '==', +searchString).limit(this.LIMIT).get().then((data) => {
            return data
          })
        ]),
        db.collection(this.collectionPath).doc(searchString).get().then((data) => {
          return { docs: data.exists ? [data] : [] }
        })
      ]
    }
    Promise.all(queries)
      .then((datas) => {
        this.docs = datas.flatMap((data) => data.docs.map((doc) => ({ id: doc.id, ...doc.data() as BaseDB })), [] as Array<BaseDB & hasDBid>) // combine results
          .reduce((acc, comp) => { // make unique
            if (!acc.find((C) => C.id === comp.id)) acc.push(comp)
            return acc
          }, [] as Array<BaseDB & hasDBid>)
      })
      .finally(() => this.isFetchingDocs = false)
      .catch((error) => {
        this.$helpers.notification.Error(error)
      })
  }
}
</script>

<style lang="scss">
</style>
