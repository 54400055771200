<template>
  <section class="svg-code-editor">
    <div class="columns">
      <div class="column is-one-third">
        <VEchoCode
          :show-print="formShowPrint"
          :code-config="codeConfig"
          :asid="formSampleAsid"
          :svg-template-text="interpolatedSVG"
        />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div ref="sticker" class="sticker-preview" v-html="interpolatedSVG" />
      </div>
      <div class="column is-one-third">
        <b-button @click="formShowPrint = !formShowPrint">formShowPrint</b-button>

        <b-field v-for="(variable,index) in variables" :key="index" :label="index">
          <b-numberinput v-model="variables[index]" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="svgTemplate">
          <b-input v-model="svgTemplate" type="textarea" placeholder="SVG" />
        </b-field>
        <b-field label="svgInterpolated">
          <b-input :value="interpolatedSVG" type="textarea" placeholder="SVG" />
        </b-field>
        <!-- <prismEditor v-model="svgTemplate" language="js" class="code-variable-wrapper" /> -->
      </div>
    </div>

    <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" />
  </section>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import VEchoCode from '@/components/VEchoCode.vue'

import 'prismjs'
import 'prismjs/themes/prism.css'
// import PrismEditor from 'vue-prism-editor'
import { CodeConfig } from '@/types/typeBackendConfig'
import { defaultCodeConfig } from '@/database/databaseSchema'
import { cloneObject } from '@/helpers/dataShapeUtil'

library.add(faUndo)


@Component({
  components: { VEchoCode }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminSVGCodeEditor extends Vue {
  public svgTemplate = ''
  public isLoading = false
  public variables = {
    outerCornerRadius: 23,
    echoCodeBGCornerRadius: 11,
    codeWidth: 224,
    codeHeight: 224,
    printPadding: 25,
    codeTopPadding: 84,
    codeSidePadding: 20,
    codeBottomPadding: 50,
    QRCodePadding: 12
  }

  public echoCodeBGCornerRadius = 0

  public formShowPrint = false
  public codeConfig: CodeConfig = { ...cloneObject(defaultCodeConfig), customText: 'some custom text', customText2: '   line 2' }
  public formSampleAsid = ''

  // private interpolate(str: string, o: any) {

  //   return str.replace(/{([^{}]*)}/g, (a, b) => {
  //     const r = o[b]
  //     return typeof r === 'string' || typeof r === 'number' ? r : a
  //   })

  // }
  private interpolate(template: string, vars: any) {
    try {
      const handler = new Function('vars', [
        'const tagged = ( ' + Object.keys(vars).join(', ') + ' ) =>',
        '`' + template + '`',
        'return tagged(...Object.values(vars))'
      ].join('\n'))

      return handler(vars)
    } catch (error: any) {
      return ''
    }
  }


  get interpolatedSVG() {
    return this.interpolate(this.svgTemplate, this.variables)
  }

  public async mounted() {
    // this.isLoading = true
    // this.tasks.forEach((t) => {
    //   t.inputPayloadString = JSON.stringify(t.taskData.payload)
    // })
    // this.isLoading = false
  }
}
</script>

<style lang="scss">
.svg-code-editor {
  .sticker-preview {
    max-width: 20em;
  }
}
</style>
