<template>
  <section>
    <!-- <b-button type="is-primary" @click="onExecuteTest">execute test</b-button> -->
    <b-button type="is-primary" @click="onExecuteAll">execute All</b-button>
    <b-button type="is-danger" @click="onDeleteAll">delete All {{ TEST_TENANT_ID_RECREATED }}</b-button>
    <b-button
      v-for="task in tasks"
      :key="task.name"
      :loading="task.isActive"
      @click="onExecute(task)"
    >{{ task.name }}</b-button>
    <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" />
  </section>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'


import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faTrash,
  faQrcode,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons'


// import { addCiModule, addTenant, addCategories, addAsidCodes, assignAndActivateAsidCodes, addProtectionModule, addFileModule, addScriptModule, addFormModule, addHtmlModule, addI18nModule, TEST_TENANT_ID_RECREATED, addCustomModule } from '@/helpers/mockData'
import { functions } from '@/firebase'
import databaseSchema from '@/database/databaseSchema'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faTrash, faQrcode, faChevronLeft)

@Component({
  components: {}
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminMockdata extends Vue {
  public isLoading = false
  public TEST_TENANT_ID_RECREATED = 'something'
  // public TEST_TENANT_ID_RECREATED = TEST_TENANT_ID_RECREATED

  public tasks: {
    name: string
    method: () => Promise<void>
    isActive: boolean
  }[]
    = [
      //   {
      //     name: 'addTenant',
      //     method: addTenant,
      //     isActive: false
      //   },
      //   {
      //     name: 'addCategories',
      //     method: addCategories,
      //     isActive: false
      //   },
      //   {
      //     name: 'addAsidCodes',
      //     method: addAsidCodes,
      //     isActive: false
      //   },
      //   {
      //     name: 'assignAndActivateAsidCodes',
      //     method: assignAndActivateAsidCodes,
      //     isActive: false
      //   },
      //   {
      //     name: ' addProtectionModule',
      //     method: addProtectionModule,
      //     isActive: false
      //   },
      //   {
      //     name: ' addFileModule',
      //     method: addFileModule,
      //     isActive: false
      //   },
      //   {
      //     name: ' addCiModule',
      //     method: addCiModule,
      //     isActive: false
      //   },
      //   {
      //     name: ' addScriptModule',
      //     method: addScriptModule,
      //     isActive: false
      //   }, {
      //     name: ' addCustomModule',
      //     method: addCustomModule,
      //     isActive: false
      //   },
      //   {
      //     name: ' addHtmlModule',
      //     method: addHtmlModule,
      //     isActive: false
      //   },
      //   {
      //     name: ' addI18nModule',
      //     method: addI18nModule,
      //     isActive: false
      //   },
      //   {
      //     name: ' addFormModule',
      //     method: addFormModule,
      //     isActive: false
      //   }
    ]

  public async onExecute(task: any) {
    try {
      task.isActive = true
      await task.method()
    } catch (error: any) {
      this.$helpers.notification.Error(error)
    } finally {
      task.isActive = false
    }
  }

  public async onExecuteAll(task: any) {
    for (const task of this.tasks) {
      try {
        task.isActive = true
        await task.method()
      } catch (error: any) {
        this.$helpers.notification.Error(error)
      } finally {
        task.isActive = false
      }
    }
  }

  // public async onExecuteTest(task: any) {
  //   await TenantManager.updatePlan('test-tenant-recreated', this.$auth.userEmail, {
  //     availableAsidSlots: 333 + Math.floor(Math.random()*100)
  //   })
  // }

  public async onDeleteAll() {
    console.log('deleting data')

    // if (process.env.VUE_APP_FIREBASE_ENV === 'emulator') {
    /**
* Call the 'recursiveDelete' callable function with a path to initiate
* a server-side delete.
*/
    const deleteAtPath = (path: string) => {
      const deleteFn = functions.httpsCallable('recursiveDeletePath')
      return deleteFn({ path: path })
    }


    deleteAtPath(databaseSchema.COLLECTIONS.TENANTS.__DOCUMENT_PATH__(this.TEST_TENANT_ID_RECREATED))
      .then((result) => {
        this.$helpers.notification.Success({ message: 'Delete success: ' + JSON.stringify(result), duration: 2000 })
      })
      .catch((err) => {
        this.$helpers.notification.Error(`Delete failed, see console, ${err}`)
      })

    // } else {


    //   try {

    //     const response = await fetch(
    //       location.protocol + '//' +
    //       location.hostname
    //       + ':5002' +
    //       '/emulator/v1/projects/' + process.env.VUE_APP_FIREBASE_PROJECTID + '/databases/(default)/documents', {
    //       method: 'DELETE',
    //       headers: {
    //         'Content-type': 'application/json'
    //       }
    //     })
    //     this.$helpers.notification.Success(response.statusText)
    //     console.log(response)

    //   } catch (error: any) {
    //     this.$helpers.notification.Error(error)
    //   }

    // }
  }

  public async mounted() {
    this.isLoading = true
    // ModuleManagerApp.name
    // await this.onInit()
    this.isLoading = false
  }
}
</script>

<style lang="scss">
.tenant-modal {
  .modal-card {
    overflow: initial;
  }

  .modal-card-body {
    overflow: visible;
  }
}
</style>
