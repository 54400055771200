<template>
  <section>
    <div v-for="(task,i) in tasks" :key="task.name">
      <h3 class="title is-4">{{ task.name }}</h3>
      <b-field grouped>
        <b-field label="Dryrun">
          <b-switch v-model="task.taskData.dryrun" />
        </b-field>
        <b-field label="Verbose">
          <b-switch v-model="task.taskData.verbose" />
        </b-field>
        <b-field label="execute">
          <b-button :loading="task.isActive" @click="onExecute(task)">{{ task.name }}</b-button>
        </b-field>

        <template v-for="setting in task.settings">
          <b-field v-if="setting.type === 'string'" :key="setting.name" :label="setting.name">
            <b-input v-model="task.taskData.payload[setting.name]" />
          </b-field>
          <b-field v-if="setting.type === 'number'" :key="setting.name" :label="setting.name">
            <b-input v-model.number="task.taskData.payload[setting.name]" />
          </b-field>
          <b-field v-if="setting.type === 'dropdown'" :key="setting.name" :label="setting.name">
            <b-select v-model="task.taskData.payload[setting.name]">
              <option v-for="(option , j) in setting.options" :key="j" :value="option">{{ option }}</option>
            </b-select>
          </b-field>
        </template>

        <b-field label="time">
          <strong>{{ (Math.floor(task.time/60000)+ '').padStart(2, '0') }}:{{ ((task.time/1000)%60 + '').padStart(2, '0') }}</strong>
        </b-field>
        <!-- state -->
        <b-field label="state">
          <strong>{{ task.state }}</strong>
        </b-field>
        <b-field v-if="task.name == 'Generall call task'" label="taskName">
          <b-input v-model="task.taskData.task" />
        </b-field>
      </b-field>
      <b-input
        v-model="task.inputPayloadString"
        type="textarea"
        placeholder="Input"
        minlength="0"
        maxlength="1000000000"
        @blur="tasks[i].taskData.payload = JSON.parse(task.inputPayloadString)"
      />
      <pre v-if="task.response.output || task.response.log.length > 0" class="log-msg">Documents changed: {{ task.response.documentsChanged }}</pre>
      <div v-if="task.response.log.length > 0" class="log-msg">
        <div
          v-for="(line, index) in task.response.log"
          :key="index"
          :class="line[0]"
        >{{ line[0] }} - {{ line[1] }} - {{ line[2] }}</div>
      </div>
      <pre v-if="task.response.output" class="log-msg">{{ task.response.output }}</pre>
      <b-input
        :value="JSON.stringify(task.response.data)"
        type="textarea"
        placeholder="Output"
        minlength="0"
        maxlength="1000000000"
      />
      <!-- if task is task is testAI , show the following output -->
      <pre v-if="task.name == 'Test AI'" class="log-msg">{{ aiQueueControlDoc.testOutput }}</pre>
      <hr />
    </div>

    <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" />
  </section>
</template>


<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleLeft, faAngleRight, faArrowUp, faChevronLeft, faChevronRight, faMars, faQrcode, faTrash, faVenus
} from '@fortawesome/free-solid-svg-icons'


import db, { functions, serverTimestamp } from '@/firebase'
import databaseSchema from '@/database/databaseSchema'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import AsidManager from '@/database/asidManager'
import { timeout } from '@/database/dbHelper'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faTrash, faQrcode, faChevronLeft)

interface ResponseData {
  output: string
  data: any
  log: Array<[string, number, string]>
  documentsChanged: number
}

interface TaskDescription {
  name: string
  isActive: boolean
  time: number
  isLocalTask?: boolean
  inputPayloadString: string
  response: ResponseData
  localTask?: (taskData: TaskDescription['taskData']) => Promise<ResponseData>
  postResponseAction: (rsp: ResponseData, task: TaskDescription) => any
  settings: {
    name: string
    type: 'dropdown' | 'number' | 'string'
    default: number | string
    value: number | string
    options: Array<number | string>
  }[]
  taskData: {
    task: string
    payload: any
    dryrun: boolean
    verbose: boolean
  }
  state: string // what is currently beeing done
}

@Component({
  components: {}
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminTasks extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public isLoading = false

  public aiQueueControlDoc: any = {
    testOutput: 'test'
  }

  public tasks: TaskDescription[] = [
    // {
    //   name: 'Export Data to Json',
    //   inputPayloadString: '',
    //   postResponseAction: rsp => downloadString(JSON.stringify(rsp.data), 'export.json'),
    //   settings: [],
    //   response: {
    //     data: null,
    //     output: '',
    //     log: [],
    //     documentsChanged: 0
    //   },
    //   taskData: {
    //     task: 'exportDataToJsonResponse',
    //     payload: {},
    //     dryrun: true,
    //     verbose: true
    //   },
    //   isActive: false,
    //   time: 0,
    //   state: 'idle'
    // }, {
    //   name: 'Import Data from Json',
    //   inputPayloadString: '',
    //   postResponseAction: rsp => rsp,
    //   settings: [],
    //   response: {
    //     data: null,
    //     output: '',
    //     log: [],
    //     documentsChanged: 0
    //   },
    //   taskData: {
    //     task: 'importDataFromJson',
    //     payload: {},
    //     dryrun: true,
    //     verbose: true
    //   },
    //   isActive: false,
    //   time: 0,
    //   state: 'idle'
    // },
    {
      name: 'Test AI',
      inputPayloadString: '',
      postResponseAction: (rsp) => rsp,
      settings: [{
        name: 'prompt',
        type: 'string',
        default: 'Quote of the day',
        value: 'Quote of the day',
        options: []
      }],
      response: {
        data: null,
        output: '',
        log: [],
        documentsChanged: 0
      },
      taskData: {
        task: 'testAI',
        payload: { prompt: 'Quote of the day' },
        dryrun: false,
        verbose: true
      },
      isActive: false,
      time: 0,
      state: 'idle'
    },
    {
      name: 'Generall call task',
      inputPayloadString: '',
      postResponseAction: (rsp) => rsp,
      settings: [],
      response: {
        data: null,
        output: '',
        log: [],
        documentsChanged: 0
      },
      taskData: {
        task: '',
        payload: {},
        dryrun: true,
        verbose: true
      },
      isActive: false,
      time: 0,
      state: 'idle'
    }, {
      name: 'clearDataAndAllChilds',
      inputPayloadString: '',
      postResponseAction: (rsp) => rsp,
      settings: [],
      response: {
        data: null,
        output: '',
        log: [],
        documentsChanged: 0
      },
      taskData: {
        task: 'clearDataAndAllChilds',
        payload: {},
        dryrun: true,
        verbose: true
      },
      isActive: false,
      time: 0,
      state: 'idle'
    }, {
      name: 'Delete To Be Deleted Documents',
      inputPayloadString: '',
      postResponseAction: (rsp) => rsp,
      settings: [{
        name: 'deleteOlderThan',
        type: 'number',
        default: 2592000000,
        value: 2592000000,
        options: []
      }, {
        name: 'limit',
        type: 'dropdown',
        default: 50,
        value: 50,
        options: [50, 100, 200, 500, 1000]
      }],
      response: {
        data: null,
        output: '',
        log: [],
        documentsChanged: 0
      },
      taskData: {
        task: 'DeleteToBeDeletedDocuments',
        payload: { deleteOlderThan: 2592000000, limit: 50 },
        dryrun: true,
        verbose: true
      },
      isActive: false,
      time: 0,
      state: 'idle'
    }, {
      name: 'Delete Old Changelog',
      inputPayloadString: '',
      postResponseAction: (rsp) => rsp,
      settings: [{
        name: 'limit',
        type: 'number',
        default: 60,
        value: 60,
        options: []
      }],
      response: {
        data: null,
        output: '',
        log: [],
        documentsChanged: 0
      },
      taskData: {
        task: 'DeleteChangelog',
        payload: { limit: 60 },
        dryrun: true,
        verbose: true
      },
      isActive: false,
      time: 0,
      state: 'idle'
    }, {
      name: 'Genrate User Auth Links',
      inputPayloadString: '',
      postResponseAction: (rsp) => rsp,
      settings: [{
        name: 'action',
        type: 'dropdown',
        default: 'verifyEmail',
        value: 'verifyEmail',
        options: ['resetPassword', 'verifyEmail']
      }, {
        name: 'email',
        type: 'string',
        default: '',
        value: '',
        options: []
      }],
      response: {
        data: null,
        output: '',
        log: [],
        documentsChanged: 0
      },
      taskData: {
        task: 'adminTaskUserManagement',
        payload: { action: 'verifyEmail', email: '' },
        dryrun: true,
        verbose: true
      },
      isActive: false,
      time: 0,
      state: 'idle'
    }, {
      name: 'Export Data to Bucket',
      inputPayloadString: '',
      postResponseAction: (rsp) => rsp,
      settings: [],
      response: {
        data: null,
        output: '',
        log: [],
        documentsChanged: 0
      },
      taskData: {
        task: 'ExportData',
        payload: {},
        dryrun: true,
        verbose: true
      },
      isActive: false,
      time: 0,
      state: 'idle'
    },
    {
      name: 'Create Asids',
      isLocalTask: true,
      localTask: async (taskData) => {
        const { asidIDs, tenantID } = taskData.payload
        const responseData: ResponseData = {
          data: null,
          output: '',
          log: [],
          documentsChanged: 0
        }

        const batch = db.batch()

        for (const asidID of asidIDs.split(',')) {
          console.log(asidID)
          if (!asidID) continue

          responseData.documentsChanged += 1
          const asidRef = AsidManager.getDbDocReference(asidID.trim())
          AsidManager.addDocBatch(asidRef, this.$auth.userEmail, { tenantID, dateAssigned: serverTimestamp() as any }, AsidManager.defaultDocDB, batch)
        }

        responseData.output += `Created ${responseData.documentsChanged} Asid codes`
        await timeout(1000)

        if (!taskData.dryrun && responseData.documentsChanged > 0)
          await batch
            .commit()
            .then((d) => {
              this.$helpers.notification.Success(`${responseData.documentsChanged} Asid codes generated`)
            })
            .catch((e) => {
              this.$helpers.notification.Error({
                message: `Error occured while creating Asid codes ${e.toString()}`
              })
            })

        return responseData
      },
      postResponseAction: (rsp) => rsp,
      settings: [{
        name: 'asidIDs',
        type: 'string',
        default: '',
        value: '',
        options: []
      }, {
        name: 'tenantID',
        type: 'string',
        default: '',
        value: '',
        options: []
      }],
      state: 'idle',
      time: 0,
      inputPayloadString: '',
      response: {
        documentsChanged: 0,
        log: [],
        data: null,
        output: ''
      },
      taskData: {
        payload: {
          asidIDs: '',
          tenantID: ''
        },
        task: 'CreateAsids',
        dryrun: true,
        verbose: true
      },
      isActive: false
    },
    {
      name: 'Migrate Data',
      inputPayloadString: '',
      postResponseAction: (rsp, task) => {
        if (task.settings?.[0]?.options) {
          task.settings[0].options = rsp.data.availableMigrations

          // add also an option to migrate all
          task.settings[0].options.push('Migrate all')

          // set the selection value to the last option
          // task.taskData.payload.migrationId = task.settings[0].options[task.settings[0].options.length - 1]
        }
      },
      settings: [{
        name: 'migrationId',
        type: 'dropdown',
        default: '',
        value: '',
        options: []
      }],
      response: {
        data: {
          availableMigrations: []
        },
        output: '',
        log: [],
        documentsChanged: 0
      },
      taskData: {
        task: 'migrate',
        payload: { migrationId: '' },
        dryrun: true,
        verbose: true
      },
      isActive: false,
      time: 0,
      state: 'idle'
    }
  ]

  private timer: any

  public async onExecute(task: TaskDescription) {
    try {
      task.isActive = true
      clearInterval(this.timer)

      task.time = 0
      this.timer = setInterval(() => {
        task.time += 1000
      }, 1000)

      console.groupCollapsed(task.name + (task.taskData.payload?.migrationId || ''))
      console.log('task', task)

      if (!task.isLocalTask) {
        // if option is Migrate All, perform all migrations
        if (task.taskData.payload.migrationId === 'Migrate all') {
          for (const m of task.settings[0].options) {
            if (m !== 'Migrate all') {
              task.taskData.payload.migrationId = m
              await this.onExecute(task)
            }
          }
          return
        }

        task.state = 'running'

        const taskFn = functions.httpsCallable('adminTasks', { timeout: 40 * 60 * 1000 })
        task.response = (await taskFn({ ...task.taskData })).data as ResponseData

        task.postResponseAction?.(task.response, task)

        task.state = 'done'
      } else if (task.localTask) {
        task.state = 'running'
        try {
          task.response = await task.localTask(task.taskData)
          task.state = 'done'
        } catch (error: any) {
          task.state = 'error'
          task.response.log.push(['error', Date.now(), error.toString()])
        }

        // switch (task.name) {
        //   case 'ExportDataLocally':
        //     // this.ExportDataLocally()
        //     break

        //   default:
        //     break
        // }
      }

      console.log('response', task.response)

      // log changed documents
      console.log('Documents changed: ' + task.response.documentsChanged)

      console.groupCollapsed('log')
      task.response.log.forEach(([severity, timestamp, text]) => {
        const date = new Date(timestamp)
        text = `${date.toLocaleDateString()} ${date.toLocaleTimeString()} - ${text}`
        switch (severity) {
          case 'info':
            console.info(text)
            break
          case 'error':
            console.error(text)
            break
          case 'warn':
            console.warn(text)
            break
          default:
            console.log(text)
            break
        }
      })
      console.groupEnd()

      // search log for errors and set state to error
      if (task.response.log.some(([severity]) => severity === 'error')) console.error('state: task has errors')
      else console.log('state: task has no errors')

      // log the execution time
      console.log('execution time: ' + task.time / 1000 + 's')

      console.groupEnd()
    } catch (error: any) {
      this.$helpers.notification.Error(error)

      task.state = 'error'
    } finally {
      clearInterval(this.timer)
      task.isActive = false
    }
  }


  public async mounted() {
    this.isLoading = true
    // this.tasks.forEach((t) => {
    //   t.inputPayloadString = JSON.stringify(t.taskData.payload)
    // })

    // bind aiQueueControlDoc to firebase function
    await this.$bindSnapshot('aiQueueControlDoc', db.doc(databaseSchema.COLLECTIONS.AI_REQUEST.__DOCUMENT_PATH__()))
    this.isLoading = false
  }

  @Watch('tasks', { deep: true, immediate: true })
  public onTaskChange(): void {
    this.tasks.forEach((t) => {
      t.inputPayloadString = JSON.stringify(t.taskData.payload)
    })
  }
}
</script>

<style lang="scss">
.log-msg {
  background-color: #2a373c;
  color: #f4fafd;
  border-radius: 0.3em;
  margin-bottom: 1em;

  -webkit-overflow-scrolling: touch;
  // background-color: whitesmoke;
  // color: #4a4a4a;
  font-size: 0.875em;
  overflow-x: auto;
  padding: 1.25rem 1.5rem;
  // white-space: pre;
  // word-wrap: normal;
  -webkit-font-smoothing: auto;
  font-family: monospace;

  .info {
    color: yellow;
  }

  .error {
    color: red;
  }
}
</style>
