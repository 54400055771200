<template>
  <section>
    <div class="columns qr-generator">
      <div class="column">
        <VEchoCode
          class="echo-sticker-preview"
          :code-config="asidDB.codeConfig"
          :attribute="asidDB.assetAttributeValue"
          :asid="asidDB.id"
          :base-url="backendConfig.asid.baseUrl"
          :backend-config="backendConfig"
          :identifier="asidDB.identifierValue"
          :data="dataByDefinitionKeys"
          :svg-template-text="svg"
        />
      </div>

      <div class="column box">
        <h1 class="subtitle">Data</h1>

        <b-field label="select tenant">
          <VAdminTenantSelectionView v-model="tenantID" />
        </b-field>

        <b-field label="Categories">
          <VInputMultiCategorySelection
            v-model="asidDB.categoryIDs"
            :categories-doc="categoryCollection"
            hide-root
          />
        </b-field>
        <br />

        <!-- <b-field label="Identifier">
        <b-input
          v-model="asidDB.identifierValue"
          :autofocus="settings.persistCategory"
          placeholder="Identifier"
          required
        />
        </b-field>-->
        <b-field>
          <template slot="label">
            Identifiers
            <VTooltipIconHelp
              text="Identifiers are variables specific to an asset, like serial number. Use categories and the Data Module when data applies for multiple ECHO Codes"
            />
          </template>
        </b-field>
        <VFormDataEntry
          ref="identifier-value"
          :data-value-object.sync="asidDB.identifierValue"
          :data-definition-object="backendConfig.asid.identifierDefinition"
          :upload-path="uploadPath"
          :display-barcode-scanner="$localSettings.asidActivate.displayBarcodeScanner"
          :document-path="docPath"
        />

        <b-field>
          <template slot="label">
            Attributes
            <VTooltipIconHelp
              text="Attributes are variables specific to an asset, like serial number. Use categories and the Data Module when data applies for multiple ECHO Codes"
            />
          </template>
        </b-field>
        <VFormDataEntry
          ref="asset-attribute-value"
          :data-value-object.sync="asidDB.assetAttributeValue"
          :data-definition-object="backendConfig.asid.assetAttributeDefinitions"
          :upload-path="uploadPath"
          :display-barcode-scanner="$localSettings.asidActivate.displayBarcodeScanner"
          :document-path="docPath"
        />
      </div>
    </div>
    <div class="columns qr-generator">
      <div class="column box">
        <h1 class="subtitle">SVG</h1>

        <VInputCodeEditor v-model="svg" pre-value="<svg>" post-value="</svg>" language="html" />
      </div>
    </div>
  </section>
</template>


<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import 'prismjs'
import 'prismjs/themes/prism.css'
// import PrismEditor from 'vue-prism-editor'
import { AsidDB } from '@/types/typeAsid'
import databaseSchema from '@/database/databaseSchema'
import { cloneObject } from '@/helpers/dataShapeUtil'
import VFormDataEntry from '@/components/VFormDataEntry.vue'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import VAdminTenantSelectionView from './components/VAdminTenantSelectionView.vue'
import DataModule from '@/modules/data/dataModule'
import { DataGroupDB, DataElementDB, isDataKey } from '@/modules/data/typeDataModule'
import { hasDBid } from '@/types/typeGeneral'
import { CategoryCollection, CategoryCollectionDocDB } from '@/types/typeCategory'
import CategoryHelper from '@/database/categoryHelper'
import { BackendConfigDB } from '@/types/typeBackendConfig'
import BackendConfigManager from '@/database/backendConfigManager'
import VEchoCode from '@/components/VEchoCode.vue'
import VInputCodeEditor from '@/components/VInputCodeEditor.vue'

library.add(faUndo)


@Component({
  components: {
    VEchoCode,
    VAdminTenantSelectionView,
    VInputMultiCategorySelection,
    VFormDataEntry,
    VInputCodeEditor
  }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminQRGenerator extends Vue {
  public asidDB: AsidDB & hasDBid = { ...cloneObject(databaseSchema.COLLECTIONS.ASID.__EMPTY_DOC__), id: '' }

  public svg: string = ''

  public tenantID: string = ''
  public uploadPath: string = ''
  public docPath: string = ''

  public backendConfig: BackendConfigDB = cloneObject(databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__EMPTY_DOC__)
  public dataModuleGroups: (DataGroupDB & hasDBid)[] = []
  public dataElements: DataElementDB[] = []
  public categoryCollectionDoc: CategoryCollectionDocDB = cloneObject(databaseSchema.COLLECTIONS.TENANTS.DATA.CATEGORIES.__EMPTY_DOC__)

  public get categoryCollection(): CategoryCollection {
    return this.categoryCollectionDoc.collection
  }

  get dataByDefinitionKeys() {
    // {varName1: 12, varNameFromOtherElement: '23'}
    let combinesDataElements: { [key: string]: any } = {}
    this.dataModuleGroups.forEach((dd) => {
      Object.entries(dd.dataDefinition).map(([key, def]) => {
        const element = this.dataElements.find((me) => me.public.groupID === dd.id)
        if (element && def.name)
          combinesDataElements[def.name] = element?.data?.[key as isDataKey]
      }
      )
    })
    return combinesDataElements
  }

  @Watch('tenantID')
  private async onTenantIDSelected() {
    this.uploadPath = `Tenants/${this.tenantID}/ASID`
    this.docPath = `Tenants/${this.tenantID}/ASID`

    await this.$firestoreBind('dataModuleGroups', DataModule.getGroupsDbReference(this.tenantID))
      .catch((e) => {
        throw `dataModuleGroups not found. ${e}[20220226]`
      })

    await this.$firestoreBind('dataElements', DataModule.getElementsDbReference(this.tenantID))

    await this.$firestoreBind('categoryCollectionDoc', CategoryHelper.getCategoriesDocRef(this.tenantID))

    await this.$firestoreBind('backendConfig', BackendConfigManager.getDbDocReference(this.tenantID))
  }

  public async mounted() {
    //
  }
}
</script>

<style lang="scss">
.qr-generator {
  .column {
    margin: 0.5rem;
  }
}
</style>
