<template>
  <section>
    <h3 class="title is-5">Backend</h3>
    <b-field label="Show Notification">
      <b-switch v-model="globalNotification.backendNotification.show" />
    </b-field>
    <b-field label="Text">
      <b-input
        v-model="globalNotification.backendNotification.text"
        type="textarea"
        placeholder="Notes"
        minlength="0"
        maxlength="10000"
      />
    </b-field>
    <b-field label="Type">
      <b-select v-model="globalNotification.backendNotification.type">
        <option value="success">ok (green)</option>
        <option value="info">info (blue)</option>
        <option value="warning">warning (yellow)</option>
        <option value="danger">error (red)</option>
      </b-select>
    </b-field>
    <b-field label="Show every Seconds">
      <b-input
        v-model="globalNotification.backendNotification.reshowEverySeconds"
        type="number"
        min="0"
      />
    </b-field>

    <hr />

    <h3 class="title is-5">App</h3>
    <b-field label="Show Notification">
      <b-switch v-model="globalNotification.appNotification.show" />
    </b-field>
    <b-field label="Text">
      <b-input
        v-model="globalNotification.appNotification.text"
        type="textarea"
        placeholder="Notes"
        minlength="0"
        maxlength="10000"
      />
    </b-field>
    <b-field label="Type">
      <b-select v-model="globalNotification.appNotification.type">
        <option value="success">ok (green)</option>
        <option value="info">info (blue)</option>
        <option value="warning">warning (yellow)</option>
        <option value="danger">error (red)</option>
      </b-select>
    </b-field>
    <b-field label="Show every Seconds">
      <b-input
        v-model="globalNotification.appNotification.reshowEverySeconds"
        type="number"
        min="0"
      />
    </b-field>

    <div class="buttons is-right" style="margin-top: 50px;">
      <b-button type="is-success" @click="onUpdateNotification">update</b-button>
      <b-button type="is-danger">Cancel</b-button>
    </div>
    <hr />
  </section>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'


import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faTrash,
  faQrcode,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons'

import databaseSchema from '@/database/databaseSchema'
import AdminNotificationManager from '@/database/adminNotificationManager'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faTrash, faQrcode, faChevronLeft)

@Component({
  components: {}
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminMockdata extends Vue {
  public isLoading = false

  public globalNotification = databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__EMPTY_DOC__
  public globalNotificationActive = false

  public async onUpdateNotification() {
    AdminNotificationManager.updateAdminNotification(this.$auth.userEmail, this.globalNotification)
      .catch((e) => this.$helpers.notification.Error(e))
  }

  public mounted() {
    AdminNotificationManager.onSnapshot((notification) => {
      this.globalNotification = notification
    }, (e) => console.error(e))
  }
}
</script>

<style lang="scss">
.tenant-modal {
  .modal-card {
    overflow: initial;
  }

  .modal-card-body {
    overflow: visible;
  }
}
</style>
