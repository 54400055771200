<template>
  <div class="admin-area">
    <header>
      <b-notification
        :active.sync="globalBackendNotificationActive"
        :type="`is-${globalBackendNotification.type}`"
        aria-close-label="Close notification"
      >
        <VEditorHtml :html="globalBackendNotification.text" />
      </b-notification>
      <b-notification
        :active.sync="globalAppNotificationActive"
        :type="`is-${globalAppNotification.type}`"
        aria-close-label="Close notification"
      >
        <VEditorHtml :html="globalAppNotification.text" />
      </b-notification>
      <TheMainMenu />
    </header>

    <section style="position: relative;">
      <div v-if="$auth.user && $auth.isEchoPrmStaff" class="container is-fullhd main-container">
        <div class="sidebar-bg" />
        <aside class="menu sidebar">
          <section>
            <p class="menu-label">User</p>
            <ul class="menu-list">
              <li>
                <span style="color: white;">{{ $auth.user.email }}</span>
              </li>
            </ul>
            <p class="menu-label">Dashboard</p>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
            </ul>
            <p class="menu-label">Tenants</p>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'tenant-list' }">
                  List Tenants
                  <VNotificationCounts :menu-notifications="menuNotifications.tenants" />
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'tenant-single', params: { id: 'new' } }">Add Tenant</router-link>
              </li>
            </ul>
            <p class="menu-label">Users</p>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'users-list' }">List Users</router-link>
              </li>
            </ul>
            <p class="menu-label">Emails</p>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'emails-list' }">
                  List Emails
                  <VNotificationCounts :menu-notifications="menuNotifications.email" />
                </router-link>
              </li>
            </ul>
            <p class="menu-label">Asid</p>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'manage-batches' }">Manage Batches</router-link>
              </li>
            </ul>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'manage-asids' }">List Asids</router-link>
              </li>
            </ul>
            <p class="menu-label">HelpCenter</p>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'helpcenter' }">Ressources</router-link>
              </li>
            </ul>

            <p class="menu-label">Tools</p>
            <!-- <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'mockdata' }">Sample Data</router-link>
              </li>
            </ul>-->
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'tasks' }">Admin Tasks</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'SVGCodeEditor' }">SVGCodeEditor</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'qr-generator' }">QR Generator</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'template-creator' }">Template Creator</router-link>
              </li>
            </ul>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: 'notification' }">Notification</router-link>
              </li>
            </ul>
          </section>
        </aside>
        <div class="main-view">
          <div class="main-view-container">
            <header class="main-header">
              <VBreadcrumb />
              <h1 v-if="route.meta.label" class="title">{{ route.meta.title }}</h1>
              <h2 v-if="route.meta.description" class="subtitle">{{ route.meta.description }}</h2>
            </header>
            <router-view v-if="!isLoading" :key="$route.fullPath" />
          </div>
        </div>
      </div>
      <div v-else-if="!$auth.user" class="not-logged-in">
        <div class="columns">
          <div class="column">
            <VFormLoginView class="login-section" />
          </div>
        </div>
      </div>
      <b-notification
        v-else-if="$auth.user && !$auth.isEchoPrmStaff"
        type="is-warning"
        aria-close-label="Close notification"
        role="alert"
      >You dont have the privileges to view this page. Only ECHO PRM staff can use this section.</b-notification>
    </section>

    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <strong>ECHO PRM - ADMIN</strong> -
          Made with ♥ in Bavaria
        </p>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import TheMainMenu from '@/components/TheMainMenu.vue'
import TheSideMenu from '@/components/TheSideMenu.vue'
import VFormLoginView from '@/components/VFormLoginView.vue'
import AdminNotificationManager from '@/database/adminNotificationManager'
import AdminManager from '@/database/adminManager'
import { GlobalAdminNotification } from '@/types/typeAdmin'
import databaseSchema from '@/database/databaseSchema'
import VEditorHtml from '@/components/global/VEditorHtml.vue'
import { getMenuNotification as getMenuNotificationEmails } from './Admin_Emails_List.vue'
import { getMenuNotification as getMenuNotificationTenants } from './Admin_Tenant_List.vue'
import { MenuNotifications } from '@/components/global/VNotificationCounts.vue'

library.add(faExclamationCircle) // used in forms with error
library.add(faEye, faEyeSlash) // used in forms to view the pw

Vue.filter('truncate', function (text: string = '', stop: number = 0, clamp: string) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '…' : '')
})
Vue.filter('truncateMid', function (text: string = '', length: number, clamp: string = '...') {
  return length < text.length
    ? text.substring(0, length / 2) + clamp + text.substring(text.length - length / 2, text.length)
    : text
})


@Component({
  components: {
    TheMainMenu,
    TheSideMenu,
    VFormLoginView,
    VEditorHtml
  }
})
export default class Admin extends Vue {
  get route() {
    return this.$route
  }

  public isLoading = true

  public menuNotifications: { email: MenuNotifications, tenants: MenuNotifications } = {
    email: { info: { count: 0, text: '' }, warning: { count: 0, text: '' }, error: { count: 0, text: '' } },
    tenants: { info: { count: 0, text: '' }, warning: { count: 0, text: '' }, error: { count: 0, text: '' } }
  }

  public async mounted() {
    try {
      this.isLoading = true
      this.showNotifications()
    } catch (error: any) {
      this.$helpers.notification.Error(JSON.stringify(error))
    } finally {
      this.isLoading = false
    }

    getMenuNotificationEmails().then((menuNotifications) => {
      this.menuNotifications.email = menuNotifications
    }).catch((e) => console.error(e))


    getMenuNotificationTenants().then((menuNotifications) => {
      this.menuNotifications.tenants = menuNotifications
    }).catch((e) => console.error(e))
  }


  @Watch('$auth.user')
  private async onUser() {
    if (!this.$auth.user) return

    try {
      this.isLoading = true
      await AdminManager.initAdminDB(this.$auth.userEmail)
      await AdminNotificationManager.initAdminNotificationDB(this.$auth.userEmail)
      this.showNotifications()
    } catch (error: any) {
      this.$helpers.notification.Error(JSON.stringify(error))
    } finally {
      this.isLoading = false
    }
  }

  public globalBackendNotification: GlobalAdminNotification = databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__EMPTY_DOC__.backendNotification
  public globalAppNotification: GlobalAdminNotification = databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__EMPTY_DOC__.appNotification
  public globalBackendNotificationActive = false
  public globalAppNotificationActive = false

  public showNotifications() {
    let intervalBackend: any = undefined
    let intervalApp: any = undefined
    AdminNotificationManager.onSnapshot((notification) => {
      this.globalBackendNotification = notification.backendNotification
      this.globalAppNotification = notification.appNotification

      clearInterval(intervalBackend)
      clearInterval(intervalApp)

      const applyShowBackend = () => {
        this.globalBackendNotificationActive = this.globalBackendNotification.show
      }
      const applyShowApp = () => {
        this.globalAppNotificationActive = this.globalAppNotification.show
      }
      intervalBackend = setInterval(applyShowBackend, this.globalBackendNotification.reshowEverySeconds * 1000)
      intervalApp = setInterval(applyShowApp, this.globalAppNotification.reshowEverySeconds * 1000)
      applyShowBackend()
      applyShowApp()
    }, (e) => console.error(e))
  }
}
</script>

<style lang="scss">
.admin-area {
  .sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 16rem;
    padding: 3rem 1rem;
    background: #2b2a27;
  }

  @media screen and (width <= 900px) {
    .sidebar {
      width: 100%;
      order: 2;
    }

    .container.main-container {
      flex-direction: column;
    }
  }

  .sidebar-bg {
    position: absolute;
    bottom: 0;
    right: 50%;
    left: 0;
    top: 0;
    background: #2b2a27;
    z-index: -1;
  }

  .not-logged-im {
    background: #2b2a27;
    padding: 10em;
  }

  .login-section {
    max-width: 400px;
    margin: auto;
    padding: 5em;
    background: white;
  }

  .main-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 80vh;
  }

  @media screen and (width <= 1471px) {
    .main-container {
      max-width: 1344px;
      width: auto;
    }
  }

  @media screen and (width <= 1087px) {
    /* .main-container {
    padding: 1.5rem;
  } */
    .sidebar-bg {
      display: none;
    }
  }

  .main-view {
    position: relative;
    background: #fff;
    overflow: hidden;
    width: 100%;
  }

  .main-view-container {
    padding: 3rem;
  }

  .main-header {
    border-bottom: 2px solid #f5f5f5;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }

  .menu-list a {
    color: #fff;
  }

  .menu-label {
    color: #f0f0f0;
  }
}
</style>
