<template>
  <section>
    <h5 style="margin-bottom: 50px;" class="title is-5">
      {{ batchDB.name }}
      <b-tag v-if="batchDB.type" style="margin-left: 10px;" type="is-primary">{{ batchDB.type }}</b-tag>
      <br />
      <small>
        <span>Asid Count: {{ batchDB._computed.asidCount }}</span>
      </small>
    </h5>

    <h5 class="title is-5">Asids - {{ batchDB._computed.asidCount }}</h5>

    <b-field group-multiline grouped>
      <b-field v-if="$props.id !== 'new'">
        <button class="button is-primary" @click="showCreateAsidsModal = true">Generate ECHO Codes</button>
      </b-field>

      <VAdminCreateAsidModal
        :external-selected-tenant-i-d="batchDB.tenantID"
        :external-batch-i-d="batchDB.id"
        :active.sync="showCreateAsidsModal"
      />

      <b-field v-if="$props.id !== 'new'">
        <button
          class="button is-primary"
          @click="showUpdateTemplateAsidsModal = true"
        >Change Templates ECHO Codes</button>
      </b-field>

      <VAdminCreateAsidModal
        change-template
        :external-selected-tenant-i-d="batchDB.tenantID"
        :external-batch-i-d="batchDB.id"
        :active.sync="showUpdateTemplateAsidsModal"
      />

      <b-field v-if="$props.id !== 'new' && batchDB.tenantID">
        <button class="button is-primary" @click="onAssignAsidsToTenant">Assign ECHO Codes to Tenant</button>
      </b-field>

      <b-field v-if="$props.id !== 'new' && batchDB._computed.asidCount > 0">
        <button class="button" @click="onCheckAsids">Check Asids</button>
      </b-field>

      <div v-if="checkAsidsResult.checked" class="box">
        <div>
          <b>All Asids same Tenant:</b>
          <b-tag
            :type="Object.keys(checkAsidsResult.tenantIDs).length === 1 ? 'is-success' : 'is-danger'"
          >{{ Object.keys(checkAsidsResult.tenantIDs).length === 1 ? 'yes' : 'no' }}</b-tag>
          <b-taglist>
            <b-tag v-for="tag in Object.keys(checkAsidsResult.tenantIDs)" :key="tag">{{ tag }}</b-tag>
          </b-taglist>
        </div>

        <div>
          <b>All Asids same Sticker Template:</b>
          <b-tag
            :type="Object.keys(checkAsidsResult.templateURLs).length === 1 ? 'is-success' : 'is-danger'"
          >{{ Object.keys(checkAsidsResult.templateURLs).length === 1 ? 'yes' : 'no' }}</b-tag>
          <b-taglist>
            <b-tag v-for="tag in Object.keys(checkAsidsResult.templateURLs)" :key="tag">{{ tag }}</b-tag>
          </b-taglist>
        </div>
      </div>

      <!-- @created="onAsidsCreated" -->

      <b-field v-if="batchDB._computed.asidCount > 0">
        <VAdminExportAsidCodes :batch-i-d="$props.id" />
      </b-field>

      <b-field v-if="$props.id !== 'new'">
        <router-link
          tag="button"
          class="button"
          :to="{ name: 'manage-asids', params: { filterByBatchID: $props.id } }"
          exact
        >Show Asids</router-link>
      </b-field>
    </b-field>

    <hr />

    <h5 class="title is-5">General</h5>

    <VAdminTenantSelectionView v-model="batchDB.tenantID" />

    <hr />

    <b-field label="batch Name">
      <b-field>
        <b-input v-model="batchDB.name" expanded minlength="0" maxlength="1000" />
        <p class="control">
          <b-button @click="onGenerateName">Generate Name</b-button>
        </p>
      </b-field>
    </b-field>

    <b-field label="responsible person ECHO PRM">
      <b-select v-model="batchDB.responsible">
        <option value="''">none</option>
        <option value="michael.trunzer@echoprm.com">Trunzer Michael - michael.trunzer@echoprm.com</option>
        <option value="andreas.trunzer@echoprm.com">Trunzer Aandreas - andreas.trunzer@echoprm.com</option>
        <option value="maximilian.beck@echoprm.com">Beck Maximilian - maximilian.beck@echoprm.com</option>
      </b-select>
    </b-field>

    <b-field label="Type">
      <b-select v-model="batchDB.type">
        <option
          v-for="option in formAvailableTypes"
          :key="option.value"
          :value="option.key"
        >{{ option.value }}</option>
      </b-select>
    </b-field>

    <hr />

    <b-field label="Reference">
      <b-input v-model="batchDB.reference" minlength="0" maxlength="1000" />
    </b-field>

    <b-field label="AB-Nummer">
      <b-input v-model="batchDB.orderConfNumber" minlength="0" maxlength="1000" />
    </b-field>

    <b-field label="Re-Nummer">
      <b-input v-model="batchDB.invoiceNumber" minlength="0" maxlength="1000" />
    </b-field>

    <b-field label="Order-Nummer">
      <b-input v-model="batchDB.orderNumber" minlength="0" maxlength="1000" />
    </b-field>

    <b-field label="Notes (internal)">
      <b-input
        v-model="batchDB.notes"
        type="textarea"
        placeholder="Notes"
        minlength="0"
        maxlength="1000"
      />
    </b-field>

    <hr />

    <h5 class="title is-5">Print</h5>

    <b-field label="Print State">
      <b-select v-model="batchDB.print.state">
        <option value="''">none</option>
        <option value="sent to printer">sent to printer</option>
        <option value="in warehouse">in warehouse</option>
        <option value="sent to tenant">sent to tenant</option>
      </b-select>
    </b-field>

    <b-field label="Print Export Notes (will be exported)">
      <b-input
        v-model="batchDB.print.notesExported"
        type="textarea"
        placeholder="Notes"
        minlength="0"
        maxlength="1000"
      />
    </b-field>

    <b-field label="Expected delivery date">
      <b-datepicker
        v-model="batchDB.print.expectedDeliveryDate"
        placeholder="Select a date..."
        icon="calendar-day"
      />
    </b-field>

    <b-field label="Layout Number">
      <b-input v-model="batchDB.print.layoutNo" minlength="0" maxlength="1000" />
    </b-field>

    <h5 class="title is-5">Print Export Document Preview</h5>
    <pre>{{ exportTxt }}</pre>

    <b-field v-if="batchDB._computed.asidCount > 0">
      <VAdminExportAsidCodes :batch-i-d="$props.id" />
    </b-field>

    <!-- <b-field label="Plan Expiration">
        <b-datepicker
          v-model="formPlanExpires"
          :min-date="new Date()"
          placeholder="Select a date..."
          icon="calendar-day"
        />
    </b-field>-->

    <!-- <b-field label="Number of ECHO Slots">
        <b-field>
          <b-numberinput v-model="plan.availableAsidSlots" controls-position="compact" step="5" />
        </b-field>
      </b-field>

      <b-field label="Echo Code Range Prefix (can not be reset!)">
        <b-field>
          <b-input
            v-model="plan.customCodeRangePrefix"
            :disabled="plan.customCodeRangePrefix.length == 5"
            :placeholder="`c${(batch._number + '').padStart(4, '0')}`"
            minlength="5"
            maxlength="5"
            pattern="c[a-z0-9]{4}"
          />
        </b-field>
    </b-field>-->

    <!-- <b-field label="PRM Modules">
      <b-taginput
        v-model="formActiveModules"
        :data="formFilteredTags"
        autocomplete
        :allow-new="false"
        open-on-focus
        icon="tag"
        placeholder="Select Modules"
        @typing="setFilterText"
      >
        <template slot-scope="props">{{ props.option }}</template>
      </b-taginput>
    </b-field>
    <hr />

    <h5 class="title is-5">Notes (not visible from batch)</h5>
    <b-field label="Notes">
      <b-input
        v-model="formAdminData.notes"
        type="textarea"
        placeholder="Notes"
        minlength="0"
        maxlength="1000"
      />
    </b-field>
    <hr />

    <h5 class="title is-5">Custom Whitelabel Domain</h5>
    <b-field label="URL">
      <b-input
        v-model="formBackendConfig.asid.baseUrl"
        type="text"
        placeholder="https://app.your-custom-domain.de"
      />
    </b-field>
    <hr />
    <h5 class="title is-5">Terms of Service</h5>

    <b-field grouped group-multiline>
      <b-field label="Accepted">
        <b-checkbox v-model="termsAccepted" disabled>Accepted</b-checkbox>
      </b-field>

      <b-field label="Accepted By">
        <b-input v-model="batch.terms.acceptedByUserId" disabled />
      </b-field>

      <b-field label="Accepted Date">
        <b-datepicker v-model="batch.terms.dateAccepted" disabled icon="calendar-day" />
      </b-field>

      <b-field label="Custom Terms Due Date">
        <b-datepicker v-model="batch.terms.acceptCustomTermsUntil" icon="calendar-day" />
      </b-field>

      <b-field label="Custom Terms Html">
        <b-input
          v-model="batch.terms.customTermsText"
          expanded
          type="textarea"
          maxlength="100000"
          placeholder="Custom Terms Html"
        />
      </b-field>

      <b-notification
        v-if="$props.id === 'new'"
        type="is-warning"
        aria-close-label="Close notification"
        role="alert"
      >
        Create the batch to be able to manage Terms.
        <b-button
          type="is-success"
          @click="updateBatch"
        >{{ $props.id === 'new' ? 'Create':'Update' }}</b-button>
      </b-notification>
      <div v-else>
        <b-button @click="onAcceptDefaultTerms()">Accept Default Terms</b-button>
        <b-button @click="onAcceptCustomTerms()">Accept Custom Terms</b-button>
      </div>

      <div v-if="batch.terms.customTermsText != ''">
        <b-button
          type="is-text"
          @click="showTermsPreview = !showTermsPreview"
        >{{ showTermsPreview?'hide':'show' }} Custom Terms Preview</b-button>
        <div v-if="showTermsPreview" class="label">Custom Terms Preview</div>

        <div
          v-if="showTermsPreview"
          class="preview"
          v-html="$sanitize(batch.terms.customTermsText)"
        />
      </div>
    </b-field>

    <hr />
    <h5 class="title is-5">Order Data Processing</h5>

    <b-field grouped group-multiline>
      <b-field label="Accepted">
        <b-checkbox v-model="odpAccepted" disabled>Accepted</b-checkbox>
      </b-field>

      <b-field label="Accepted By">
        <b-input v-model="batch.odp.acceptedByUserId" disabled />
      </b-field>

      <b-field label="Accepted Date">
        <b-datepicker v-model="batch.odp.dateAccepted" disabled icon="calendar-day" />
      </b-field>

      <b-field label="Custom Odp Due Date">
        <b-datepicker v-model="batch.odp.acceptCustomTermsUntil" icon="calendar-day" />
      </b-field>

      <b-field label="Custom Odp Html">
        <b-input
          v-model="batch.odp.customTermsText"
          expanded
          type="textarea"
          maxlength="100000"
          placeholder="Custom Odp Html"
        />
      </b-field>

      <b-notification
        v-if="$props.id === 'new'"
        type="is-warning"
        aria-close-label="Close notification"
        role="alert"
      >
        Create the batch to be able to manage Odp.
        <b-button
          type="is-success"
          @click="updateBatch"
        >{{ $props.id === 'new' ? 'Create':'Update' }}</b-button>
      </b-notification>
      <div v-else>
        <b-button @click="onAcceptDefaultOdp()">Accept Default Odp</b-button>
        <b-button @click="onAcceptCustomOdp()">Accept Custom Odp</b-button>
      </div>

      <div v-if="batch.odp.customTermsText != ''">
        <b-button
          type="is-text"
          @click="showOdpPreview = !showOdpPreview"
        >{{ showOdpPreview?'hide':'show' }} Custom Odp Preview</b-button>
        <div v-if="showOdpPreview" class="label">Custom Odp Preview</div>
        <div v-if="showOdpPreview" class="preview" v-html="$sanitize(batch.odp.customTermsText)" />
      </div>
    </b-field>


    <hr />

    <h5 class="title is-5">Base Data</h5>

    <b-field label="Batch Name">
      <b-input v-model="batch.name" placeholder="Batch Name" required />
    </b-field>

    <b-field grouped group-multiline expanded>
      <b-field label="Phone" expanded>
        <b-input v-model="batch.masterData.phone" placeholder="Phone" type="tel" required />
      </b-field>

      <b-field label="Email" expanded>
        <b-input
          v-model="batch.masterData.email"
          placeholder="Email Adress"
          type="email"
          placholder="Email Adress"
          maxlength="500"
          required
        />
      </b-field>

      <b-field label="Billing Email" expanded>
        <b-input
          v-model="batch.masterData.billingEmail"
          placeholder="Billing Email Adress"
          type="email"
          placholder="Billing Email Adress"
          maxlength="500"
          required
        />
      </b-field>
    </b-field>

    <hr />

    <h5 class="title is-5">Users</h5>
    <b-notification
      v-if="$props.id === 'new'"
      type="is-warning"
      aria-close-label="Close notification"
      role="alert"
    >
      Create the batch to be able to add users.
      <b-button type="is-success" @click="updateBatch">{{ $props.id === 'new' ? 'Create':'Update' }}</b-button>
    </b-notification>
    <VInputMultiUserManagementView
      v-else
      ref="userRef"
      :batch-id="$props.id"
      :batch-name="batch.name"
      :autosave="false"
    />

    <hr />

    <h5 class="title is-5">Billing Address</h5>
    <VFormAddress v-model="batch.masterData.billingAddress" />

    <hr />

    <div class="level">
      <div class="level-left">
        <h5 class="title is-5">Shipping Address</h5>
      </div>
      <div class="level-right">
        <b-button type="is-text" @click="onCopyBillingToShipping">Copy from Billing</b-button>
      </div>
    </div>
    <VFormAddress v-model="batch.masterData.shippingAddress" />-->

    <div class="buttons is-right" style="margin-top: 50px;">
      <b-button type="is-success" @click="updateBatch">{{ $props.id === 'new' ? 'Create':'Update' }}</b-button>
      <b-button type="is-danger">Cancel</b-button>
    </div>
    <hr />
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'


import VFormAddress from '@/components/VFormAddress.vue'
import VInputUserRolesTags from '@/components/VInputUserRolesTags.vue'
import VInputMultiUserManagementView from '@/components/VInputMultiUserManagementView.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay, faPlus, faMinus, faEnvelope, faTag } from '@fortawesome/free-solid-svg-icons'


import VAdminTenantSelectionView from './components/VAdminTenantSelectionView.vue'
import VAdminExportAsidCodes from './components/VAdminExportAsidCodes.vue'
import { cloneObject } from '@/helpers/dataShapeUtil'

import { BatchDB } from '@/types/typeBatch'
import { hasDBid } from '@/types/typeGeneral'
import BatchManager from '@/database/batchManager'
import VAdminCreateAsidModal from './components/VAdminCreateAsidModal.vue'
import moment from 'dayjs'
import TenantManager from '@/database/tenantManager'
import AsidManager from '@/database/asidManager'
import { AsidDB } from '@/types/typeAsid'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'

library.add(faCalendarDay, faPlus, faMinus, faEnvelope, faTag, faCalendarDay)


@Component({
  components: {
    VFormAddress,
    VInputUserRolesTags,
    VInputMultiUserManagementView,
    VAdminTenantSelectionView,
    VAdminExportAsidCodes,
    VAdminCreateAsidModal
  },
  props: {
    id: {
      type: String,
      required: true,
      default: () => 'new'
    }
  }
})
export default class AdminBatchSingle extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public showCreateAsidsModal = false
  public showUpdateTemplateAsidsModal = false

  public isLoading: boolean = false
  public formAvailableTypes = [{ value: 'Print', key: 'print' }, { value: 'Other', key: 'other' }]

  public batchDB: BatchDB & hasDBid = cloneObject({ ...BatchManager.defaultDocDB, id: '' })

  public get exportTxt() {
    return BatchManager.generatePrintExportTxt(this.batchDB)
  }

  public async onGenerateName() {
    this.isLoading = true
    if (this.batchDB.tenantID) {
      const tenant = await TenantManager.get(this.batchDB.tenantID)

      this.batchDB.name = `${moment().format('D-M-YYYY')}_${tenant.name}`
    } else {
      this.batchDB.name = `${moment().format('D-M-YYYY')}_generic`
    }
    this.isLoading = false
  }

  public checkAsidsResult = {
    tenantIDs: {},
    templateURLs: {},
    checked: false
  }

  private async onCheckAsids() {
    await this.checkAsids()
  }

  private async checkAsids() {
    try {
      this.isLoading = true
      this.checkAsidsResult.checked = false
      this.checkAsidsResult.tenantIDs = {}
      this.checkAsidsResult.templateURLs = {}

      const asidDocs = await AsidManager.getWhere<AsidDB>({ batchIDs: [] }, 'array-contains', this.batchDB.id)

      const incrementProp = (obj: any, prop?: string | null) => {
        prop = prop || 'none'

        if (!obj[prop])
          this.$set(obj, prop, 0)

        obj[prop]++
      }

      asidDocs.forEach((ad) => {
        incrementProp(this.checkAsidsResult.tenantIDs, ad.tenantID)
        incrementProp(this.checkAsidsResult.templateURLs, ad.codeConfig.svgTemplateUrl)
      })

      this.checkAsidsResult.checked = true
    } catch (error: any) {
      this.$helpers.notification.Error(`Error occured while checking Asid codes: ${error.toString()}`)
    } finally {
      this.isLoading = false
    }
  }

  private async onAssignAsidsToTenant() {
    if (!this.batchDB.tenantID || this.batchDB._computed.asidCount === 0) {
      this.$helpers.notification.Warn('No Tenant selected or no Asids available')
      return
    }

    try {
      this.isLoading = true
      const asidDocs = await AsidManager.getWhere<AsidDB>({ batchIDs: [] }, 'array-contains', this.batchDB.id)

      await AsidManager.assignAsids(this.batchDB.tenantID, this.$auth.userEmail, asidDocs.map((a) => a.id))
        .then((d) => {
          this.$helpers.notification.Success(`${asidDocs.length} Asid codes Assigned`)
        })
    } catch (error: any) {
      this.$helpers.notification.Error(`Error occured while assigning Asid codes: ${error.toString()}`)
    } finally {
      this.isLoading = false
    }
  }

  public async updateBatch() {
    // validation
    let isValid = true
    // if (this.batch.name.length < 2) {
    //   this.$helpers.notification.Warn('Batch name to short')
    //   isValid = false
    // }
    if (!isValid) return

    this.isLoading = true


    let batchID: string = this.$props.id
    if (this.$props.id === 'new') {
      try {
        batchID = (await BatchManager.add(this.$auth.userEmail, this.batchDB)).id

        this.$helpers.notification.Success('Batch created')
        await this.$router.push({ name: 'batches-single', params: { id: batchID } })
      } catch (e: any) {
        this.$helpers.notification.Error('Error creating Batch ' + e)
      }
    } else {
      await BatchManager.update(this.batchDB.id, this.$auth.userEmail, this.batchDB)
    }

    this.$helpers.notification.Success('Batch saved')

    this.isLoading = false
  }


  public async created() {
    this.isLoading = true

    this.batchDB = cloneObject({ ...BatchManager.defaultDocDB, id: '' })

    if (this.$props.id !== 'new') {
      try {
        await this.$bindSnapshot('batchDB', BatchManager.getDbDocReference(this.$props.id))
      } catch (e: any) {
        this.$helpers.notification.Error(e.toString())
      }
    }

    this.isLoading = false
  }
}
</script>

<style scoped>
.user-roles {
  margin-bottom: 30px;
}
</style>
