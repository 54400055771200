<template>
  <section>
    <b-modal :active.sync="isModalcreateAsidCodesActive" :width="640" scroll="keep">
      <div class="modal-card changelog-diff" style="width: auto;">
        <header class="modal-card-head">
          <p
            class="modal-card-title"
          >{{ changeTemplate ? 'Change Template' : 'Create Codes' }} {{ (createAsidCodesSelectedTenant)?'for Tenant '+createAsidCodesSelectedTenant.name:'' }}</p>
          <button type="button" class="delete" @click="isModalcreateAsidCodesActive = false" />
        </header>
        <section class="modal-card-body">
          <b-steps v-model="activeStep" :animated="true" :rounded="true" :has-navigation="true">
            <b-step-item step="1" label="Sticker Type">
              <h1 class="title has-text-centered">Type</h1>

              <template v-if="!externalSelectedTenantID">
                <b-field label="Custom Code - Find a Tenant">
                  <VAdminTenantSelectionView
                    v-model="selectedTenantID"
                    @selected="activeStep = 2-1"
                  />
                </b-field>
                <hr />

                <b-button
                  expanded
                  type="is-primary "
                  @click="activeStep = 2-1;createAsidCodesSelectedTenant=null"
                >Generic Code</b-button>
              </template>
              <span
                v-else
              >Type is "Custom Code" since a tenant is already selected. Create codes for tenant: {{ createAsidCodesSelectedTenant && createAsidCodesSelectedTenant.name }}</span>

              <hr />

              <b-field label="Code Usage Type">
                <b-select v-model="formCodeType">
                  <option value="product-based">product based (default)</option>
                  <option value="time-limited">limited time product based</option>
                  <option value="batch-code">batch code (one code on multiple products)</option>
                  <option value="subscription">subscription</option>
                </b-select>
              </b-field>
            </b-step-item>

            <b-step-item :visible="!changeTemplate" step="2" label="Quantity">
              <h1 class="title has-text-centered">Quantity</h1>

              <b-field label="How many ECHO Codes do you want to create">
                <b-numberinput
                  v-model="formCreateAsidCodesQuantity"
                  placeholder="100"
                  :min="0"
                  step="1"
                />
              </b-field>
            </b-step-item>

            <b-step-item step="3" label="Sticker">
              <b-message
                v-if="!!createAsidCodesSelectedTenant"
                title="Warning"
                type="is-warning"
                aria-close-label="Close message"
              >You are editing the templates of a customer</b-message>

              <h1 class="title has-text-centered">Configure Sticker Template</h1>
              <b-field>
                <template slot="label">
                  Select Template Preset
                  <VTooltipIconHelp text="Select the template to use for your stickers" />
                </template>
                <b-field grouped>
                  <b-field>
                    <b-select v-model="formSelectedCodeConfig" placeholder="Default">
                      <option
                        v-for="code in formAvailableCodeConfigs"
                        :key="code.id"
                        :value="code"
                      >Template {{ code.id }}</option>

                      <!-- <option v-for="codeConfig in backendConfig.codes">SVGTemplate5</option> -->
                    </b-select>

                    <p class="control">
                      <b-button icon-left="trash" @click="onRemoveCodeConfig">remove</b-button>
                    </p>
                    <p class="control">
                      <b-button type="is-success" @click="onUpdateCodeConfig">update</b-button>
                    </p>
                  </b-field>
                  <b-field>
                    <p class="control">
                      <b-button @click="onAddCodeConfig">add template</b-button>
                    </p>
                  </b-field>
                </b-field>
              </b-field>
              <hr />
              <VFormConfigureSticker v-model="formSelectedCodeConfig" />
              <!-- :disabled="!!createAsidCodesSelectedTenant" -->
            </b-step-item>

            <b-step-item :visible="!externalBatchID" step="4" label="Batch">
              <h1 class="title has-text-centered">Select a Batch</h1>
              <VDocSelectionView
                v-model="batchID"
                placeholder="Tenant Name or Number"
                :input-formatter="(t)=>`${t.name} - id: ${(t.id)} `"
                :collection-path="'Admin/Batch/Batches'"
                :query-like-props="['name']"
                :query-exact-props="[]"
              >
                <template #dropdown="{option}">
                  <div>
                    {{ option.name }} - id: {{ option.id }}
                    <br />
                    <!-- <small>Address: {{ option.masterData.billingAddress.city || 'no city' }}, {{ option.masterData.billingAddress.street || 'no street' }}</small> -->
                  </div>
                </template>
              </VDocSelectionView>
              <hr />
            </b-step-item>

            <b-step-item step="5" label="Summary">
              <b-notification
                type="is-info"
                :closable="false"
                aria-close-label="Close notification"
              >{{ state }}</b-notification>

              <h1 class="title has-text-centered">Confirm</h1>

              <div>
                <div>
                  <b>Operation:</b>
                  {{ changeTemplate ? 'change template' : 'create asids' }}
                </div>
                <div>
                  <b>Numer of ECHO codes & Stickers:</b>
                  {{ formCreateAsidCodesQuantity + changeTemplateAsidDbDocs.length }}
                </div>
                <div>
                  <b>Sticker Template:</b>
                  {{ formSelectedCodeConfig.svgTemplateUrl }}
                </div>
                <div v-if="batch">
                  <b>Batch:</b>
                  {{ batch.name }}
                </div>
                <div v-if="batch">
                  <b>Batch ID:</b>
                  {{ batch.id }}
                </div>
                <div>
                  <b>ECHO Code Error correction Level:</b>
                  {{ formSelectedCodeConfig.errorCorrectionLevel }}
                </div>
                <div>
                  <b>Code Type:</b>
                  {{ formCodeType }}
                </div>
                <div>
                  <b>Assigned Tenant:</b>
                  {{ (createAsidCodesSelectedTenant && createAsidCodesSelectedTenant.name) || 'none - generic code' }}
                </div>
                <div>
                  <b>ECHO Code Base Url:</b>
                  {{ formCreateAsidCodesBaseUrl }}
                </div>
                <div>
                  <b>ECHO customText:</b>
                  {{ formSelectedCodeConfig.customText }}
                </div>
                <div>
                  <b>ECHO width:</b>
                  {{ formSelectedCodeConfig.width }}mm
                </div>
                <div>
                  <b>ECHO height:</b>
                  {{ formSelectedCodeConfig.height }}mm
                </div>
              </div>
              <hr />
              <b-field v-if="!isProcessDone">
                <p class="control">
                  <b-button
                    expanded
                    type="is-success"
                    @click="onConfirmCodeCreation"
                  >confirm and {{ changeTemplate ? 'change template' : 'create codes' }}</b-button>
                </p>
              </b-field>
              <b-field v-else>
                <p class="control">
                  <b-button expanded @click="isModalcreateAsidCodesActive = false">close</b-button>
                </p>
              </b-field>
            </b-step-item>
            <!-- <b-step-item step="5" label="Finish" :type="{'is-success': true}">
              <h1 class="title has-text-centered">Finish</h1>Yur request has been sent.
            </b-step-item>-->
          </b-steps>
        </section>
        <b-loading :is-full-page="false" :active="isLoading > 0" :can-cancel="false" />
      </div>
    </b-modal>
  </section>
</template>


<script lang="ts">
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faTrash,
  faQrcode,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons'

import VImportExport from '@/components/VImportExport.vue'
import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'
import VEchoCode from '@/components/VEchoCode.vue'
import VFilterDropdownView from '@/components/VFilterDropdownView.vue'
import VFilterDateDropdownView from '@/components/VFilterDateDropdownView.vue'

import VAdminTenantSelectionView from './VAdminTenantSelectionView.vue'
import { AsidDB, asidType } from '@/types/typeAsid'

import AsidManager from '@/database/asidManager'
import { TenantDB } from '@/types/typeTenant'
import TenantManager from '@/database/tenantManager'

import BackendConfigManager from '@/database/backendConfigManager'
import { CodeConfig } from '@/types/typeBackendConfig'
import AdminManager from '@/database/adminManager'

import { defaultCodeConfig } from '@/database/databaseSchema'
import { GlobalAdminCodeConfigDB } from '@/types/typeAdmin'

import { hasDBid } from '@/types/typeGeneral'
import VDocSelectionView from './VDocSelectionView.vue'
import BatchManager from '@/database/batchManager'
import { BatchDB } from '@/types/typeBatch'
import db, { serverTimestamp } from '@/firebase'
import { getChunkedArray } from '@/helpers/arrayHelper'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { timeout } from '@/database/dbHelper'

library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faTrash, faQrcode, faChevronLeft)

@Component({
  components: {
    VImportExport,
    VFormConfigureSticker,
    VEchoCode,
    VFilterDropdownView,
    VFilterDateDropdownView,
    VAdminTenantSelectionView,
    VDocSelectionView
  }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class VAdminCreateAsidModal extends Vue {
  public isLoading = 0
  public isProcessDone = false

  @PropSync('active', { required: true, type: Boolean, default: false })
  public isModalcreateAsidCodesActive!: boolean

  @Prop({ required: false })
  public externalSelectedTenantID!: string

  @Prop({ required: false })
  public externalBatchID!: string

  @Prop({ required: false, type: Boolean })
  public changeTemplate!: boolean

  public batch: null | BatchDB & hasDBid = null
  public batchID = ''

  public formCodeType: asidType = 'product-based'

  public changeTemplateAsidDbDocs: (AsidDB & hasDBid)[] = []

  @Watch('batchID')
  private async onChangebatchID() {
    try {
      this.isLoading++
      this.batch = await BatchManager.get(this.batchID)
    } catch (error) {
      this.$helpers.notification.Error(error)
    }
    this.isLoading--
  }

  @Watch('activeStep', { immediate: true })
  public async stateMachine() {
    switch (this.activeStep) {
      case 0: // reset
        this.backendConfig = cloneObject(BackendConfigManager.defaultDocDB)
        // this.createAsidCodesSelectedTenant = null
        await this.onChangeExternalSelectedTenantID()
        this.activeStep = 0
        this.formCodeType = 'product-based'
        this.isProcessDone = false
        this.formCreateAsidCodesQuantity = 0
        this.formCreateAsidCodesBaseUrl = ''
        this.adminCodeConfig = cloneObject(AdminManager.defaultAdminCodeConfigDB)
        this.formSelectedCodeConfig = cloneObject(BackendConfigManager.defaultDocDB.codes[0])
        this.formAvailableCodeConfigs = []
        break

      case 1:
        break

      case 4: // overview
        this.isLoading++
        if (this.changeTemplate) {
          this.state = 'loading asids'
          this.changeTemplateAsidDbDocs = await AsidManager.getWhere<AsidDB>({ batchIDs: [] }, 'array-contains', this.batchID)
        }
        this.state = 'waiting for export'
        this.isLoading--
        break

      default:
        break
    }
  }

  // #region generate Asids

  public backendConfig = { ...BackendConfigManager.defaultDocDB }

  public createAsidCodesSelectedTenant: null | TenantDB & hasDBid = null

  public activeStep = 0

  public formCreateAsidCodesQuantity = 0
  public formCreateAsidCodesBaseUrl = ''


  public adminCodeConfig: GlobalAdminCodeConfigDB = cloneObject(AdminManager.defaultAdminCodeConfigDB)


  public formSelectedCodeConfig = cloneObject(BackendConfigManager.defaultDocDB.codes[0])

  public formAvailableCodeConfigs: CodeConfig[] = []

  @Watch('externalSelectedTenantID', { immediate: true })
  private async onChangeExternalSelectedTenantID() {
    // if an external id is given disable the local tenant seleceltion
    if (this.externalSelectedTenantID) this.selectedTenantID = this.externalSelectedTenantID
  }

  @Watch('externalBatchID', { immediate: true })
  private async onChangeExternalBatchtID() {
    if (this.externalBatchID) this.batchID = this.externalBatchID
  }

  public selectedTenantID = ''
  @Watch('selectedTenantID', { immediate: true })
  private async onChangeSelectedTenantID() {
    if (!this.selectedTenantID) {
      this.createAsidCodesSelectedTenant = null
      return
    }
    try {
      this.isLoading++
      this.createAsidCodesSelectedTenant = await TenantManager.get(this.selectedTenantID)
    } catch (error) {
      this.$helpers.notification.Error(error)
    }
    this.isLoading--
  }

  public async onAddCodeConfig() {
    try {
      this.isLoading++

      if (this.createAsidCodesSelectedTenant) {
        this.formAvailableCodeConfigs.push({ ...defaultCodeConfig, id: '' + (this.formAvailableCodeConfigs.length + 1) })
        await BackendConfigManager.update(this.createAsidCodesSelectedTenant.id, this.$auth.userEmail, { codes: this.formAvailableCodeConfigs })
      } else {
        this.adminCodeConfig.config.codes.push({ ...defaultCodeConfig, id: '' + (this.adminCodeConfig.config.codes.length + 1) })
        await AdminManager.updateCodeConfig(this.$auth.userEmail, this.adminCodeConfig)
      }
    } catch (error: any) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading--
    }
  }

  public async onRemoveCodeConfig() {
    try {
      this.isLoading++

      if (this.formAvailableCodeConfigs.length === 1) return

      const removeIndex = this.formAvailableCodeConfigs.indexOf(this.formSelectedCodeConfig)
      this.formAvailableCodeConfigs.splice(removeIndex, 1)

      if (this.createAsidCodesSelectedTenant) {
        await BackendConfigManager.update(this.createAsidCodesSelectedTenant.id, this.$auth.userEmail, { codes: this.formAvailableCodeConfigs })
      } else {
        await AdminManager.updateCodeConfig(this.$auth.userEmail, { config: { codes: this.formAvailableCodeConfigs } })
      }
    } catch (error: any) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading--
    }
  }

  public async onUpdateCodeConfig() {
    try {
      this.isLoading++

      if (this.createAsidCodesSelectedTenant) {
        await BackendConfigManager.update(this.createAsidCodesSelectedTenant.id, this.$auth.userEmail, { codes: this.formAvailableCodeConfigs })
      } else {
        await AdminManager.updateCodeConfig(this.$auth.userEmail, { config: { codes: this.formAvailableCodeConfigs } })
      }
    } catch (error: any) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading--
    }
  }

  public state = ''
  public async onConfirmCodeCreation() {
    this.isLoading++
    let docsUpdated = 0
    try {
      if (this.changeTemplate) {
        for (const chunksOfChangesByID of getChunkedArray(this.changeTemplateAsidDbDocs, 400)) {
          const batch = db.batch()

          for (const changePerID of chunksOfChangesByID) {
            console.log(changePerID)

            AsidManager.updateBatch(changePerID.id, this.$auth.userEmail, {
              codeConfig: this.formSelectedCodeConfig,
              type: this.formCodeType
            }, batch)
          }

          await timeout(1000)

          await batch
            .commit()
            .then((d) => {
              docsUpdated += chunksOfChangesByID.length
              this.$helpers.notification.Success(`${(docsUpdated / this.changeTemplateAsidDbDocs.length * 100).toFixed(2)}% ${docsUpdated} of ${this.changeTemplateAsidDbDocs.length} Asid codes updated`)
            })
            .catch((e) => {
              this.$helpers.notification.Error({
                message: `Error occured while updating Asid codes ${e.toString()}`
              })
            })

          this.state = `${(docsUpdated / this.changeTemplateAsidDbDocs.length * 100).toFixed(2)}% ${docsUpdated} of ${this.changeTemplateAsidDbDocs.length} Asid codes updated`
          await this.$nextTick()
        }
      } else {
        const numberOfCodes = this.formCreateAsidCodesQuantity
        const codeConfig = this.formSelectedCodeConfig
        const asid: Partial<AsidDB> = {
          codeConfig,
          type: this.formCodeType
        }

        if (this.createAsidCodesSelectedTenant?.id) {
          asid.tenantID = this.createAsidCodesSelectedTenant?.id
          asid.dateAssigned = serverTimestamp() as any
        }

        asid.batchIDs = this.batchID ? [this.batchID] : []
        // const batch = db.batch()

        // todo make sure asids in the database are not overridden on id collissions


        for (const chunksOfChangesByID of getChunkedArray(Array(+numberOfCodes).fill(0), 400)) {
          const batch = db.batch()

          for (const changePerID of chunksOfChangesByID) {
            console.log(changePerID)

            const asidRef = AsidManager.getDbDocReference(AsidManager.createID())
            AsidManager.addDocBatch(asidRef, this.$auth.userEmail, asid, AsidManager.defaultDocDB, batch)
          }

          await timeout(1000)

          await batch
            .commit()
            .then((d) => {
              docsUpdated += chunksOfChangesByID.length
              this.$helpers.notification.Success(`${docsUpdated} Asid codes generated`)
            })
            .catch((e) => {
              this.$helpers.notification.Error({
                message: `Error occured while creating Asid codes ${e.toString()}`
              })
            })

          this.state = `${(docsUpdated / numberOfCodes * 100).toFixed(2)}% ${docsUpdated} of ${numberOfCodes} Asid codes created`
          await this.$nextTick()
        }
      }

      this.isProcessDone = true
    } catch (error: any) {
      this.$helpers.notification.Error({
        message: `Error occured  ${error.toString()}`
      })
    } finally {
      this.$emit('created')
      this.isLoading--
    }
  }

  @Watch('isModalcreateAsidCodesActive')
  private onTenantDocUpdate() {
    if (!this.isModalcreateAsidCodesActive) this.activeStep = 0
  }

  @Watch('createAsidCodesSelectedTenant', { immediate: true })
  @Watch('adminCodeConfig', { immediate: true })
  private async oncreateAsidCodesSelectedTenantChange() {
    let codesConfig = this.adminCodeConfig.config.codes
    if (this.createAsidCodesSelectedTenant) {
      const backendConfig = (await BackendConfigManager.get(this.createAsidCodesSelectedTenant.id))
      codesConfig = backendConfig.codes
      this.formCreateAsidCodesBaseUrl = AsidManager.createLink('{asid}', backendConfig.asid.baseUrl)
    } else {
      this.formCreateAsidCodesBaseUrl = AsidManager.createLink('{asid}')
    }

    this.formAvailableCodeConfigs = codesConfig
  }


  private async initCreateAsid() {
    await this.$firestoreBind('adminCodeConfig', AdminManager.getCodeConfigDocRef(), { wait: true })
  }

  @Watch('formAvailableCodeConfigs', { immediate: true })
  private onBackendConfigUpdate() {
    if (this.formAvailableCodeConfigs.length === 0) return
    this.formSelectedCodeConfig = this.formAvailableCodeConfigs[(this.formAvailableCodeConfigs.length - 1)]
  }

  public async createAsidCodes() {
    this.activeStep = 0
    this.isModalcreateAsidCodesActive = true
  }


  // #endregion


  public async mounted() {
    await this.initCreateAsid()
  }
}
</script>

<style lang="scss">
.tenant-modal {
  .modal-card {
    overflow: initial;
  }

  .modal-card-body {
    overflow: visible;
  }
}

input.input[lowercase='true'] {
  text-transform: lowercase;
}
</style>
