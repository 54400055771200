<template>
  <section class="help-center">
    <VHelpCenterView :admin-mode="true" />
  </section>
</template>


<script lang="ts">


import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle, faBook, faSearch } from '@fortawesome/free-solid-svg-icons'


import { Component, Vue } from 'vue-property-decorator'


library.add(faSearch, faBook, faTimesCircle)


@Component({
  components: {}
})
export default class AdminHelpCenter extends Vue {


}
</script>

<style lang="scss">
.help-center {
  .column {
    // background: green;
    // border: 1px solid red;
    .videoWrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }

    .videoWrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.help-menu {
      max-width: 20em;
    }

    .faq h5.title.is-6 {
      margin-top: 1.5em;
      margin-bottom: 0.5em;
    }

    .panel {
      position: sticky;
      top: 1em;

      .level {
        width: 100%;
      }
    }
  }
}
</style>
