<template>
  <section>
    <b-field grouped group-multiline>
      <b-select v-model="pagination_perPage">
        <option value="10">10 per page</option>
        <option value="100">100 per page</option>
        <option value="500">500 per page</option>
        <option value="2000">2000 per page</option>
      </b-select>

      <b-checkbox
        v-for="(column, index) in columnsVisible"
        :key="index"
        v-model="columnsVisible[index]"
      >{{ index }}</b-checkbox>

      <b-checkbox v-model="formDoFilterByActive" class="level-left">
        Filter by
        <b>active</b> tenants
      </b-checkbox>
    </b-field>

    <b-table
      ref="table"
      :data="pagination_paginatedData"
      :backend-pagination="true"
      pagination-simple
      paginated
      :backend-sorting="!pagination_allLoaded"
      :total="pagination_totalItemsEstimation"
      :per-page="pagination_perPage"
      :checked-rows.sync="checkedRows"
      :default-sort="[pagination_sortField, pagination_sortDirection]"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      checkable
      filters-event="input"
      narrowed
      @filters-event-input="onFiltersChange"
      @page-change="onPageChange"
      @sort="onSort"
    >
      <b-input
        v-if="!props.column.numeric"
        slot="searchable"
        v-model="props.filters[props.column.field]"
        slot-scope="props"
        placeholder="Search..."
        icon="search"
        size="is-small"
      />

      <b-table-column field="_number" label="Number" sortable :searchable="pagination_allLoaded">
        <template #default="props">
          <b-tooltip
            type="is-dark"
            multilined
            :label="props.row.id"
            animated
          >{{ formatPadded(props.row._number) }}</b-tooltip>
        </template>
      </b-table-column>

      <b-table-column field="name" label="Tenant Name" sortable :searchable="pagination_allLoaded">
        <template #default="props">{{ props.row.name }}</template>
      </b-table-column>

      <b-table-column
        :visible="columnsVisible.Email"
        field="masterData.email"
        label="Email"
        sortable
      >
        <template #default="props">{{ props.row.masterData.email }}</template>
      </b-table-column>

      <b-table-column
        field="masterData.billingAddress.city"
        label="Address"
        sortable
        :searchable="pagination_allLoaded"
      >
        <template #default="props">
          <span v-if="props.row.masterData.billingAddress.city">
            {{ props.row.masterData.billingAddress.city }}, {{ props.row.masterData.billingAddress.street }}
            {{ props.row.masterData.billingAddress.number }}
          </span>
        </template>
      </b-table-column>

      <b-table-column field="plan" label="PlanDB" sortable>
        <template #default="props">
          <b-tag>{{ (props.row.plan) ? getPlanName(props.row.plan.type) : 'loading...' }}</b-tag>
        </template>
      </b-table-column>

      <b-table-column field="activatedAsids" label="Codes (active/available)">
        <template #default="props">
          <b-progress
            :value="(props.row.plan)?props.row.plan._computed.activatedAsids/props.row.plan.availableAsidSlots*100:undefined"
            size="is-medium"
            show-value
            :type="(props.row.plan && props.row.plan._computed.activatedAsids > props.row.plan.availableAsidSlots)?'is-danger':(props.row.plan && props.row.plan._computed.activatedAsids > props.row.plan.availableAsidSlots*0.9)?'is-warning':''"
            class="code-progress"
          >{{ props.row.plan && props.row.plan._computed.activatedAsids || 0 }}/{{ props.row.plan && props.row.plan.availableAsidSlots || 0 }}</b-progress>
        </template>
      </b-table-column>

      <b-table-column field="assignedAsids" label="Codes assigned to tenant">
        <template #default="props">
          <b-progress
            :value="(props.row.plan)?props.row.plan._computed.assignedAsids/props.row.plan.availableAsidSlots*100:undefined"
            size="is-medium"
            show-value
            class="code-progress"
          >{{ props.row.plan && props.row.plan._computed.assignedAsids || 0 }}/{{ props.row.plan && props.row.plan.availableAsidSlots || 0 }}</b-progress>
        </template>
      </b-table-column>

      <b-table-column field="activatedInteractions" label="Interactions (used/available)">
        <template #default="props">
          <b-progress
            :value="(props.row.plan)?props.row.plan._computed.totalUsedInteractions/getAvailableInteractionsCount(props.row.plan)*100:undefined"
            size="is-medium"
            show-value
            class="code-progress"
          >{{ props.row.plan && props.row.plan._computed.totalUsedInteractions || 0 }}/{{ props.row.plan && getAvailableInteractionsCount(props.row.plan) || 0 }}</b-progress>
        </template>
      </b-table-column>

      <b-table-column
        field="_meta.dateCreated"
        label="Date Created"
        sortable
        centered
        :searchable="pagination_allLoaded"
      >
        <template #default="props">{{ props.row._meta.dateCreated.toDate().toLocaleDateString() }}</template>
      </b-table-column>

      <b-table-column label="Actions" centered>
        <template #default="props">
          <b-field>
            <!-- button with external link icon which links to the backend -->
            <p class="control">
              <b-button
                tag="a"
                :href="`${backendBaseUrl}/?tenant-id=${props.row.id}`"
                size="is-small"
                icon-right="external-link-alt"
              />
            </p>
            <p class="control">
              <b-button
                tag="router-link"
                :to="{ name: 'tenant-single', params: { id: props.row.id } }"
                size="is-small"
                icon-right="edit"
              />
            </p>
            <p class="control">
              <b-button
                outlined
                type="is-danger"
                size="is-small"
                icon-right="trash"
                @click="formRemoveTenant(props.row.id)"
              />
            </p>
          </b-field>
        </template>
      </b-table-column>
    </b-table>
  </section>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEdit, faExternalLinkAlt, faSearch
} from '@fortawesome/free-solid-svg-icons'

import Tenant, { TenantDB } from '@/types/typeTenant'
import TenantManager from '../../database/tenantManager'
import VPaginationMixin from '../../components/mixins/VPaginateMixin.vue'
import { mixins } from 'vue-class-component'
import AsidManager from '@/database/asidManager'
import { PlanDB, PlanType } from '@/types/typePlan'
import { hasDBid, objectID } from '@/types/typeGeneral'
import { MenuNotifications } from '@/components/global/VNotificationCounts.vue'
import databaseSchema from '@/database/databaseSchema'
import { typedWhereV9 } from '@/database/dbHelper'
import db from '@/firebase'
import { collectionGroup, getDocs } from 'firebase/firestore'
import { FilterConfigNew } from '@/database/filterUtil'
import { plans } from '@/businessLogic/plans'
import { handlePromiseError } from '@/helpers/notificationHelper'


library.add(faEdit, faSearch, faExternalLinkAlt)


export async function getMenuNotification(): Promise<MenuNotifications> {
  // get all plans where the type is active_plan

  const activePlansQuery = typedWhereV9(
    collectionGroup(db, databaseSchema.COLLECTIONS.TENANTS.DATA.__NAME__),
    { type: 'active_plan' }, '==', 'active_plan'
  )

  const activePlansDocSnaps = await getDocs(activePlansQuery)

  const activePlans = activePlansDocSnaps.docs.map((doc) => doc.data() as PlanDB)

  // check if the _computed.activatedAsids > availableAsidSlots

  const activePlansWithNotOverusedAsids = activePlans.filter((plan) => plan._computed.activatedAsids < plan.availableAsidSlots)

  // if only 10% of the availableAsidSlots are left, show a warning
  const activePlansWithAlmostOverusedAsids = activePlansWithNotOverusedAsids.filter((plan) => plan._computed.activatedAsids > plan.availableAsidSlots * 0.9)

  return {
    info: { count: 0, text: '' },
    warning: { count: activePlansWithAlmostOverusedAsids.length, text: 'Tenants with less than 10% free slots' },
    error: { count: activePlans.length - activePlansWithNotOverusedAsids.length, text: 'Tenants without free slots' }
  }
}

@Component({
  components: {}
})
export default class AdminTenantList extends mixins<VPaginationMixin<TenantDB>>(VPaginationMixin) {
  public pagination_sortDirection: 'asc' | 'desc' = 'desc'
  public pagination_sortField: string = '_number'
  public pagination_perPage = 500

  public checkedRows: Array<TenantDB & hasDBid & { plan: PlanDB }> = []
  public isLoading = false
  public pagination_paginatedData: Array<TenantDB & hasDBid & { plan: PlanDB }> = []

  protected pagination_collectionPath = TenantManager.getDbCollectionReference().path

  public backendBaseUrl = `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_BACKEND_BASE_URL}`

  public columnsVisible = {
    Email: false
  }

  public getPlanName(planType: PlanType) {
    return plans[planType]
  }

  public formDoFilterByActive = false
  private tenantIDwithActivePlan: Array<objectID> = []

  @Watch('formDoFilterByActive')
  public async onChangeFormDoFilterByActive() {
    const activePlansQuery = typedWhereV9(
      collectionGroup(db, databaseSchema.COLLECTIONS.TENANTS.DATA.__NAME__),
      { type: 'active_plan' }, '==', 'active_plan'
    )

    const activePlansDocSnaps = await getDocs(activePlansQuery)

    const activePlanPaths = activePlansDocSnaps.docs.map((doc) => doc.ref.path)

    this.tenantIDwithActivePlan = activePlanPaths.map((path) => path.split('/')[1])

    // reset fetch queue
    this.planDocIDsFetched = []
    this.planDocIDsFetchQueue = []
    this.pagination_getData(true)
  }

  protected pagination_filter(): FilterConfigNew<TenantDB & hasDBid>[] {
    return (this.formDoFilterByActive)
      ? [{
        fieldAccessor: { id: '' },
        opStr: 'in',
        values: this.tenantIDwithActivePlan,
        indexGroups: [],
        isMandatory: false
      }]
      : []
  }


  // protected filter(query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
  // filter does only work when grouped by the filter property.
  // It does not really work in combination with pagination. -> only filter locally if all elements are loaded
  // console.log('wugawuga')
  // const searchText = 'AC'
  // this.pagination_sortField = 'name'
  // return query.startAt(searchText).endAt(searchText + '\uf8ff')
  // // return typedWhere<TenantDB>(query, { name: '' }, '>=', 'ACME')
  // }

  public formatPadded(number: number) {
    return TenantManager.formatPaddedNumber(number)
  }

  public onSort(field: string, order: 'asc' | 'desc') {
    console.log(field, order)

    this.pagination_sortField = field
    this.pagination_sortDirection = order
  }

  public onPageChange(page: number = 1) {
    this.pagination_currentPage = page
  }

  public getAvailableInteractionsCount(plan: PlanDB) {
    return AsidManager.getAvailableInteractionsCount(plan)
  }

  private filteredColumns: Tenant[] = []

  public onFiltersChange(doc: any) {
    console.log('onFiltersChange', doc)
    const tableRef = this.$refs.table as any
    this.filteredColumns = tableRef?.newData ? tableRef.newData : []

    console.log('filteredColumns', this.filteredColumns)
  }

  public beforeDestroy() {
    clearInterval(this.interval)
    this.interval = 0
  }

  @Watch('filteredColumns')
  private onFilteredColumnsChange() {
    console.log('onFilteredColumnsChange', this.filteredColumns)

    // add the filtered tenant ids to the top of the fetch plan queue
    this.planDocIDsFetchQueue = this.filteredColumns.map((U: Tenant) => U.id).concat(this.planDocIDsFetchQueue)
  }

  private planDocIDsFetchQueue: Array<string> = []
  private planDocIDsFetched: Array<string> = [] // fetched or fetching
  private interval: any = 0
  private concurrentFetches = 0

  private throttledGetPlanDoc() {
    const FETCH_DOCS_PER_SECOND = 10
    const SIMULTANEOUS_FETCHES = 5


    const fetchDocs = async () => {
      // filter out all duplicates, keeping the first occurence
      this.planDocIDsFetchQueue = this.planDocIDsFetchQueue.filter((value, index, self) => self.indexOf(value) === index)

      // filter out already fetched
      this.planDocIDsFetchQueue = this.planDocIDsFetchQueue.filter((value) => !this.planDocIDsFetched.includes(value))

      console.log('fetchDocs', this.planDocIDsFetchQueue.length, this.planDocIDsFetched.length, this.concurrentFetches)


      if (this.concurrentFetches >= SIMULTANEOUS_FETCHES) return

      if (this.planDocIDsFetchQueue.length > 0) {
        const docID = this.planDocIDsFetchQueue.shift()
        if (docID) {
          this.planDocIDsFetched.push(docID)

          this.concurrentFetches++


          console.log('fetchDoc', docID)
          const s = await TenantManager.getDbPlanDocReference(docID).get()
          const plan = s.data() as PlanDB
          const index = this.pagination_paginatedData.findIndex((U: Tenant) => U.id === docID)

          this.concurrentFetches--

          if (index !== -1) {
            this.$set(this.pagination_paginatedData[index], 'plan', plan)
          }
        }
      } else {
        clearInterval(this.interval)
        this.interval = 0
      }
    }

    if (this.interval === 0)
      this.interval = setInterval(() => handlePromiseError(fetchDocs()), 1000 / FETCH_DOCS_PER_SECOND)
  }

  protected async pagination_foreachDoc(doc: any) {
    this.planDocIDsFetchQueue.push(doc.id as string)


    this.throttledGetPlanDoc()


    // (TenantManager.getDbPlanDocReference(doc.id).get())
    //   .then((s) => {
    //     const plan = s.data() as PlanDB

    //     console.log('plan', plan)
    //     if (!plan) return
    //     this.$set(doc, 'plan', plan)
    //   })
  }

  public created() {
    this.getData()
  }

  public formRemoveTenant(id: string) {
    const tmpTenant = this.pagination_paginatedData.find((U: Tenant) => U.id === id)// todo is this check neccessary?

    if (tmpTenant) {
      this.$buefy.dialog.confirm({
        title: 'Deleting account',
        message: `Are you sure you want to <b>delete ${tmpTenant.name} </b> account and all linked ECHO CODES? This action cannot be undone.`,
        confirmText: 'Delete Account',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true

          TenantManager.deleteTenantAndLinkedAsids(id)
            .then((result) => {
              this.$helpers.notification.Success({ message: 'Delete success: ' + JSON.stringify(result), duration: 2000 })
              this.getData(true)
            })
            .catch((err) => {
              this.$helpers.notification.Error(`Delete failed, see console, ${err}`)
            }).finally(() => this.isLoading = false)
        }
      })
    }
  }
}
</script>


 <style lang="scss">
.code-progress {
  min-width: 100px;

  progress.progress::-webkit-progress-bar {
    background-color: #999;
  }
}
</style>
